import React, { useEffect, useState } from "react";
import {
    Navbar,
    Button,
    Tabs,
    Modal,
    Drawer,
    CopyTextButton,
    Field,
    Select,
    TableSkeleton,
    Calendar,
    CampaignsWizard
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useFormatter from "../../hooks/useFormatter";
import TrackingDrawer from "./tracking-drawer/TrackingDrawer";



const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    CodeType,
    trashV2,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function Tracking() {
    const [campaignData, setCampaignData] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const { fade } = useGlobalContext().animations;
    const {
        getAdvertiserPixelStatsRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        isLoading: { campaignDataIsLoading },
        trackingState,
        DeleteConversionPixelRequest,
        ensureTokenValid,
        isTokenExpired,
        updateTokens,
         refreshAccessToken,
    } = useGlobalContext();

    const [isStatLoading, setIsStatLoading] = useState(false);



    const { setDisplayInstallPixel, setDefaultTab, setIsEditingMode, setEditingTagId, setState } = trackingState;
    const { formatDateToYYYYMMDD } = useFormatter();

    const setAffiliateid = (website) => {
        setSelectedCampaign(website);
        setEditingTagId(website.affiliateid);
       // localStorage.removeItem("requestedData");
    };
    useEffect(() => {
        document.title = "Tracking";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Monitor your progress and stay updated with real-time data on your activities.";
 
        const fetchCampaignData = async () => {
            const user = localStorage.getItem("user");
            const parsedUser = JSON.parse(user);

            try {
             
                //await ensureTokenValid(); // Ensure the token is valid before making the request
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` + parsedUser.access_token, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "user_id": parsedUser.user_id
                    }),
                });
                const result = await response.json();
                setCampaignData(result.result); // Store fetched data in state

                
            } catch (error) {
                
                if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                    await updateTokens();
                    const user = await JSON.parse(localStorage.getItem("user"));
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` + user.access_token, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "user_id": parsedUser.user_id
                        }),
                    });
                    const result = await response.json();
                    setCampaignData(result.result); // Store fetched data in state

                   // localStorage.removeItem("user"); // Remove user from localStorage
                   // window.location.href = '/sign-in'; // Redirect to the login page
                    
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                }
            }   
        
                
        
            
        };

        fetchCampaignData();
    }, [getAdvertiserPixelStatsRequest]);

    const handleEditClick = (campaign) => {
        setSelectedCampaign(campaign);
        setIsEditModalOpen(true);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
        setSelectedCampaign(null);
    };

    const handleEditSave = (updatedCampaign) => {
        // Update campaign data here
        handleEditModalClose();
    };

    return ( 
        <motion.main {...fade} className="main tracking-page">
            <Navbar title="My Websites" />
            <CampaignsWizard />
            
            <br />

            <TrackingDrawer />

            {campaignDataIsLoading ? (
                <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
                <>
                    <div className="scroll shadow">
                        <table className="conversion-table">
                            <thead> 
                                <tr>
                                    <th className="text-start">Website Name</th>
                                    <th>Conversions</th>
                                    <th>Date created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!Array.isArray(campaignData) || campaignData.length === 0 ? (
                                    <tr style={{ pointerEvents: "none" }}>
                                        <td colSpan="10">
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    campaignData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-start">
                                                {item.name || 'unknown'}
                                            </td>
                                            <td className="text-center">
                                                {item.pixelConversionStats?.[0]?.conversionCount || 0}
                                            </td>
                                            <td className="text-center">
                                                {item.updated || 0}
                                            </td>
                                            <td style={{ width: 0 }} className="text-center table-td-actions">
                                                <div className="btn-group">
                                                    <Button
                                                        variant="circle"
                                                        icon={penV2}
                                                        color="dark"
                                                        size="sm"
                                                        onClick={() => {
                                                             setDisplayInstallPixel("block");
                                                            setDefaultTab(0);
                                                            setIsEditingMode(true);
                                                            setAffiliateid(item)
                                                            setEditingTagId(item.affiliateid);
                                                            setState({ tagName: item.name, url: "", contains: "" });
                                                        }}
                                                    />
                                                    <Button
                                                        variant="circle"
                                                        icon={trashV2}
                                                        color="danger"
                                                        size="sm"
                                                        // onClick={() =>
                                                        //     DeleteConversionPixelRequest({
                                                        //         tagId: item.affiliateid,
                                                        //         setIsLoading: setIsStatLoading,
                                                        //     })
                                                        // }
                                                        disabled={isStatLoading}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <br /> 
                    <br />
                </>
            )}
            <CodeType className="tracking-cover" />            
        </motion.main>
    );

    
}
