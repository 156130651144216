import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials, 
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useConvertKit from "../../../hooks/useConvertKit";

import { auth, db } from "../../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDoc, doc } from "firebase/firestore";

export default function SignUp() {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref") || null;
    const [signupDisabled, setSignupDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const { fade } = useGlobalContext().animations;
    const {
        errorAlert,
        successAlert,
        setIsLoggedIn,
        handleGoogleSignIn,
        handleGoogleSignUp,
        setInviteData,
        inviteData,
        setRefCode,
        requestedData,
        initRequests
    } = useGlobalContext();
    const { eye, eyeSlash } = localData.svgs;
    const { logoDark, google, facebook, preloaderBig } = localData.images;
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [raw, setRaw] = useState({
        email: "",
        password: "",
        username:"",
        name:"",
        invited: false,
        inviteOrgID: "",
        referralCode: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setRaw({ ...raw, [name]: value });
    };

    const { register } = useFetch();
    const { sendConvertKit } = useConvertKit();
    var access_token;

    const autoLogin = async () => {    

        try { 
            // Replace with your API call to check authentication
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/login.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                   // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Adjust according to your API
                },
                body: JSON.stringify({ 
                    username: raw.email,
                    password: raw.password,
                    "client_id":"blockchain-ads",
                    "client_secret":"blockchain-adsapi",
                    "grant_type":"password"
                }),
            });
            const data = await response.json();           
            if (!data.error) {                
                successAlert("You have successfully signed in!");
                setIsLoggedIn(true); 
                localStorage.setItem("user",JSON.stringify(data));
                navigate("/");
                //setAuthUser(data);
                initRequests();
            } else {
                errorAlert(data.error_description);
                setIsLoggedIn(false);
            }
        } catch (err) {
            console.error(err);
            setIsLoggedIn(false);
        }
        
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        try {
            const token = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/token.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({                   
                    "client_id":"blockchain-ads",
                    "client_secret":"blockchain-adsapi",
                    "grant_type":"client_credentials"
                }),
            });

            access_token = await token.json();

            const data = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/publisher-signup.php?access_token=`+access_token.access_token, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: raw.email,
                    password: raw.password,
                    email:raw.email,
                    name:raw.name
                })           
                
            });
            const data2 = await data.json();           
            console.log(data2);
            if('result' in data2){
                errorAlert(data2.result);
            }  else {
                await autoLogin();
            }
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=newSignUp= request error");
        }
        setIsLoading(false);
    };

    useEffect(() => {
        function checkIfInvited() {
            const fullHref = window.location.href;
            let href = fullHref.split("?");
            if (href.length > 1 && !refCode) {
                href = href[1];
                const paramData = href.split("&");
                // return {
                //     isInvited: true,
                //     data: {invite: true, organizationID: paramData[1].split("=")[1] }
                // }

                setInviteData({
                    ...inviteData,
                    invited: true,
                    inviteOrgID: paramData[1].split("=")[1],
                });
                return setRaw({
                    ...raw,
                    invited: true,
                    inviteOrgID: paramData[1].split("=")[1],
                });
            } else {
                // return {
                //     isInvited: false,
                //     data: null,
                // };
                return setRaw({ ...raw, invited: false });
            }
        }
        checkIfInvited();
        if (refCode) {
            setRaw({ ...raw, referralCode: refCode });
            handleReferalBlur();
            setRefCode(refCode);
        }
    }, []);

    const handleReferalBlur = async () => {
        const validationErrors = {};

        if (!raw.referralCode) {
            setSignupDisable(false);
            return;
        }
        setIsLoading(true);
        setSignupDisable(true);
        const userdata = await getUserById(raw.referralCode);

        if (userdata) {
            setSignupDisable(false);
        } else {
            validationErrors.referralCode = "Invalid Referral Code! Add new or remove the current";
        }
        setErrors(validationErrors);
        setIsLoading(false);
    };

    const getUserById = async (userId) => {
        try {
            const usersCollection = collection(db, "users");

            const userDocRef = doc(usersCollection, userId);
            const userSnapshot = await getDoc(userDocRef);

            if (!userSnapshot.exists()) {
                return null;
            }

            const user = userSnapshot.data();
            return user;
        } catch (error) {
            console.error("Error getting user by ID:", error);
            throw error;
        }
    };

    return (
        <motion.main {...fade} className="main sign-up-page">
            <div className="container">
                <div className="col col-left">
                    <div className="wrapper">
                        <form className="form" onSubmit={handleRegister}>
                            <img className="logo" src={logoDark} />

                            <div className="form-description">Sign in or create an account</div>

                            <h2 className="form-title">Sign Up</h2>

                            {/* <Field
                                label="Organization"
                                color="secondary"
                                placeholder="Enter your organization"
                                required={true}
                                name="organization"
                                value={raw.organization}
                                callback={handleOnChange}
                            /> */}
                            <Field
                                type="name"
                                label="Name"
                                color="secondary"
                                placeholder="Enter your Name"
                                required={true}
                                name="name"
                                value={raw.name}
                                callback={handleOnChange}
                            />

                            <Field
                                type="email"
                                label="Email"
                                color="secondary"
                                placeholder="Enter your email"
                                required={true}
                                name="email"
                                value={raw.email}
                                callback={handleOnChange}
                            />
                            <Field
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                color="secondary"
                                placeholder="Enter your password"
                                required={true}
                                name="password"
                                value={raw.password}
                                callback={handleOnChange}
                                // endIcon={eye}
                            >
                                <Button
                                    tabIndex={-1}
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="field-endIcon"
                                    icon={showPassword ? eye : eyeSlash}
                                    variant="circle"
                                    size="sm"
                                    color="secondary"
                                    type="button"
                                />
                            </Field>
                            {raw.referralCode && (
                                <Field
                                    type="text"
                                    label="Referral Code"
                                    color="secondary"
                                    placeholder="Enter your referral code"
                                    required={false}
                                    name="referralCode"
                                    value={raw.referralCode}
                                    callback={handleOnChange}
                                    onBlur={handleReferalBlur}
                                    readOnly={true}
                                    errorMessage={
                                        signupDisabled && errors.referralCode && errors.referralCode
                                    }
                                />
                            )}
                            <Button
                                className="submit-btn"
                                name="Register"
                                color="primary"
                                variant="contained"
                                size="lg"
                                icon={
                                    isLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isLoading}
                            />

                            {/* <div style={{ display: "none" }} className="convertkit-data">
                                <input
                                    type="text"
                                    name="from_name"
                                    value={requestedData.userProfileData.userName}
                                    onChange={() => {}}
                                />
                                <input
                                    type="text"
                                    name="from_email"
                                    value={requestedData.userProfileData.userEmail}
                                    onChange={() => {}}
                                />
                            </div> */}
                        </form>
                        <div className="cut-line">
                            <div className="cut-line-inner">or</div>
                        </div>

                        {/* <Button
                            variant="outlined"
                            size="lg"
                            color="secondary"
                            className="alt social-btn google-btn"
                            onClick={handleGoogleSignUp}
                        >
                            <span className="startIcon">
                                <img src={google} alt="" />
                            </span>
                            Sign up with Google
                        </Button> */}

                        {/* <Button
                            variant="outlined"
                            size="lg"
                            color="secondary"
                            className="alt social-btn facebook-btn"
                        >
                            <span className="startIcon">
                                <img src={facebook} alt="" />
                            </span>
                            Sign in with Facebook
                        </Button> */}
                        <p className="login-signup-now">
                            Already have an account?
                            <Link to="/sign-in" underline="hover" className="link link-primary">
                                {" "}
                                Sign in
                            </Link>
                        </p>
                    </div>
                    <p className="copyright">
                        ©Blockchain-Ads. All rights reserved.
                        <Link to="/privacy-policy" underline="hover" className="link link-primary">
                            {" "}
                            Privacy Policy
                        </Link>
                    </p>
                </div>

                <div className="col col-right">
                    <Testimonials />
                </div>
            </div>
        </motion.main>
    );
}

// ===========================================================================================================

// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";
// import { useGlobalContext } from "../../../context";
// import {
//     Navbar,
//     Button,
//     Select,
//     Geochart,
//     TrafficSourcesTable,
//     SocialMetrics,
//     Field,
//     Testimonials,
// } from "../../../components";
// import localData from "../../../localData";
// import { Link, useNavigate } from "react-router-dom";
// import useFetch from "../../../hooks/useFetch";

// import { auth } from "../../../config/firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";

// export default function SignIn() {
//     const { fade } = useGlobalContext().animations;
//     const {
//         errorAlert,
//         successAlert,
//         setIsLoggedIn,
//         handleGoogleSignIn,
//         handleGoogleSignUp,
//         setInviteData,
//         inviteData,
//     } = useGlobalContext();
//     const { eye, eyeSlash } = localData.svgs;
//     const { logoDark, google, facebook, preloaderBig } = localData.images;

//     const [showPassword, setShowPassword] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     const [raw, setRaw] = useState({
//         email: "",
//         password: "",
//         invited: false,
//         inviteOrgID: "",
//     });

//     const handleOnChange = (e) => {
//         const { name, value } = e.target || e;
//         setRaw({ ...raw, [name]: value });
//     };

//     const { register } = useFetch();

//     const autoLogin = async () => {
//         try {
//             await signInWithEmailAndPassword(auth, raw.email, raw.password);
//             successAlert("Your account has been created successfully!");
//         } catch (err) {
//             errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
//              console.log("error " + err);
//         }
//     };

//     const handleRegister = async (e) => {
//         e.preventDefault();
//         setIsLoading(true);
//         try {
//             const data = await register(() => {}, raw);
//             await autoLogin();
//             console.log(data, "=newSignUp= request");
//         } catch (err) {
//             errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
//             console.error(err, "=newSignUp= request error");
//         }
//         setIsLoading(false);
//     };

//     useEffect(() => {
//         function checkIfInvited() {
//             const fullHref = window.location.href;
//             let href = fullHref.split("?");

//             if (href.length > 1) {
//                 href = href[1];
//                 const paramData = href.split("&");
//                 // return {
//                 //     isInvited: true,
//                 //     data: {invite: true, organizationID: paramData[1].split("=")[1] }
//                 // }

//                 setInviteData({
//                     ...inviteData,
//                     invited: true,
//                     inviteOrgID: paramData[1].split("=")[1],
//                 });
//                 return setRaw({
//                     ...raw,
//                     invited: true,
//                     inviteOrgID: paramData[1].split("=")[1],
//                 });
//             } else {
//                 // return {
//                 //     isInvited: false,
//                 //     data: null,
//                 // };
//                 return setRaw({ ...raw, invited: false });
//             }
//         }
//         checkIfInvited();
//     }, []);

//     return (
//         <motion.main {...fade} className="main sign-up-page">
//             <div className="container">
//                 <div className="col col-left">
//                     <div className="wrapper">
//                         <form className="form" onSubmit={handleRegister}>
//                             <img className="logo" src={logoDark} />

//                             <div className="form-description">
//                                 Sign in or create an account
//                             </div>

//                             <h2 className="form-title">Sign Up</h2>

//                             {/* <Field
//                                 label="Organization"
//                                 color="secondary"
//                                 placeholder="Enter your organization"
//                                 required={true}
//                                 name="organization"
//                                 value={raw.organization}
//                                 callback={handleOnChange}
//                             /> */}

//                             <Field
//                                 type="email"
//                                 label="Email"
//                                 color="secondary"
//                                 placeholder="Enter your email"
//                                 required={true}
//                                 name="email"
//                                 value={raw.email}
//                                 callback={handleOnChange}
//                             />
//                             <Field
//                                 type={showPassword ? "text" : "password"}
//                                 label="Password"
//                                 color="secondary"
//                                 placeholder="Enter your password"
//                                 required={true}
//                                 name="password"
//                                 value={raw.password}
//                                 callback={handleOnChange}
//                                 // endIcon={eye}
//                             >

//                                 <Button
//                                     tabIndex={-1}
//                                     onClick={() => setShowPassword(!showPassword)}
//                                     className="field-endIcon"
//                                     icon={showPassword ? eye : eyeSlash}
//                                     variant="circle"
//                                     size="sm"
//                                     color="secondary"
//                                     type="button"
//                                 />
//                             </Field>

//                             <Button
//                                 className="submit-btn"
//                                 name="Register"
//                                 color="primary"
//                                 variant="contained"
//                                 size="lg"
//                                 icon={
//                                     isLoading ? (
//                                         <span className="endIcon">
//                                             <img src={preloaderBig} />
//                                         </span>
//                                     ) : null
//                                 }
//                                 disabled={isLoading}
//                             />
//                         </form>
//                         <div className="cut-line">
//                             <div className="cut-line-inner">or</div>
//                         </div>

//                         <Button
//                             variant="outlined"
//                             size="lg"
//                             color="secondary"
//                             className="alt social-btn google-btn"
//                             onClick={handleGoogleSignUp}
//                         >
//                             <span className="startIcon">
//                                 <img src={google} alt="" />
//                             </span>
//                             Sign up with Google
//                         </Button>

//                         {/* <Button
//                             variant="outlined"
//                             size="lg"
//                             color="secondary"
//                             className="alt social-btn facebook-btn"
//                         >
//                             <span className="startIcon">
//                                 <img src={facebook} alt="" />
//                             </span>
//                             Sign in with Facebook
//                         </Button> */}
//                         <p className="login-signup-now">
//                         Already have an account?
//                             <Link
//                                 to="/sign-in"
//                                 underline="hover"
//                                 className="link link-primary"
//                             >
//                                 {" "}
//                                 Sign in
//                             </Link>
//                         </p>
//                     </div>
//                     <p className="copyright">
//                         ©Blockchain-Ads. All rights reserved.
//                         <Link
//                             to="/privacy-policy"
//                             underline="hover"
//                             className="link link-primary"
//                         >
//                             {" "}
//                             Privacy Policy
//                         </Link>
//                     </p>
//                 </div>

//                 <div className="col col-right">
//                     <Testimonials />
//                 </div>
//             </div>
//         </motion.main>
//     );
// }
