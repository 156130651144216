import React, { useState, useEffect } from "react";
import { Tabs, Select, CopyTextButton } from "../../../../../components";
import { useGlobalContext } from "../../../../../context";
import localData from "../../../../../localData";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../tracking-help/TrackingHelp";

const { pixel } = localData.images;

export default function CreateConversionTab({ setActiveTab, callbackFromParent }) {
    const [showCreateConversionModal, setShowCreateConversionModal] = useState("none");

    const { trackingState,
         ensureTokenValid,
        isTokenExpired,
        updateTokens,
         refreshAccessToken, } = useGlobalContext();
    const [requestedData, setRequestedData] = useState([]);
    const { editingTagId, isEditingMode } = trackingState;

    useEffect(() => {
        const fetchZoneData = async () => {
            const user = JSON.parse(localStorage.getItem("user"));

            try {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=` + user.access_token, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "affiliateid": editingTagId
                    }),
                });

               
                const result = await response.json();
                setRequestedData(result.result);
                localStorage.setItem("requestedData", JSON.stringify(result.result));

            } catch (error) {
                console.error('Fetch error:', error);
                if (error.name === 'TypeError' && error.message === 'Failed to fetch') {                   
                    await updateTokens();
                    const user = await JSON.parse(localStorage.getItem("user"));
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=` + user.access_token, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "affiliateid": editingTagId
                        }),
                    });

                    const result = await response.json();
                    setRequestedData(result.result);
                    localStorage.setItem("requestedData", JSON.stringify(result.result));

                } else {
                    console.error('An error occurred:', error);
                }
            }
        };

        fetchZoneData();
    }, [editingTagId, isEditingMode]);

    const createConversioFormTabs = [
        {
            title: "Track an event",
            content: <TrackAnEventContent requestedData={requestedData} />,
            id: "track-an-event-2983jfosfod",
        },
    ];

    return (
        <div className="create-conversion-tab">
            <h2 className="create-conversion-tab-title display-2"></h2>

            <Tabs
                tabsProps={{}}
                tabs={createConversioFormTabs}
                title="Create conversion"
                className="create-conversion-tabs"
            />
            <br />
            <div className="create-conversion-tab-footer">
            </div>
        </div>
    );
}

const TrackAnEventContent = ({ requestedData }) => {
    const { conversionOptions: { trackAnEventScriptTag }, setConversionOptions, trackingState } = useGlobalContext();
    const { isEditingMode } = trackingState;
    const [state, setState] = useState({ tagName: "", url: "", contains: "" });
    const [items, setItems] = useState([]);
    const {   ensureTokenValid,
       isTokenExpired,
       updateTokens,
        refreshAccessToken, } = useGlobalContext();
    useEffect(() => {
        if(Array.isArray(requestedData)){
            if (requestedData.length > 0) {
                const options = requestedData.map(zone => ({
                    id: zone.zoneid,
                    title: zone.zonename,
                    isActive: false
                }));
                setItems(options);
            }
        }
        
    }, [requestedData]);

    const handleSelectChange = (updatedItems, name) => {
        const itemsArray = Array.isArray(updatedItems) ? updatedItems : [updatedItems];
        const selectedItem = itemsArray.find(item => item.isActive === true);

        if (!selectedItem) {
            console.error("No item is selected in items:", itemsArray);
            return;
        }

        setState((state) => ({ ...state, [name]: selectedItem.title }));

        const fetchInvocationData = async () => {
            const user = JSON.parse(localStorage.getItem("user"));

            try {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-invocation.php?access_token=` + user.access_token, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "zone_id": selectedItem.id,
                        "type": 2
                    }),
                });

               
                const result = await response.json();
                setConversionOptions(prevState => ({
                    ...prevState,
                    trackAnEventScriptTag: {
                        ...prevState.trackAnEventScriptTag,
                        isLoading: false,
                        value: result
                    }
                }));

            } catch (error) {
                console.error('Fetch error:', error);
                if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                    await updateTokens();
                    const user = await JSON.parse(localStorage.getItem("user"));
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-invocation.php?access_token=` + user.access_token, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "zone_id": selectedItem.id,
                            "type": 2
                        }),
                    });
    
                   
                    const result = await response.json();
                    setConversionOptions(prevState => ({
                        ...prevState,
                        trackAnEventScriptTag: {
                            ...prevState.trackAnEventScriptTag,
                            isLoading: false,
                            value: result
                        }
                    }));
                    //localStorage.removeItem("user");
                    //window.location.href = '/sign-in';
                } else {
                    console.error('An error occurred:', error);
                }
            }
        };

        fetchInvocationData();
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className="step-1">
            <br />
            <div className="form-legend">
                <form action="" onSubmit={handleOnSubmit}>
                    <Select
                        items={items}
                        setItems={setItems}
                        placeholder="Select Zone"
                        isLoading={false}
                        variant="outlined"
                        color="light"
                        className="organization-select"
                        callback={(items) => handleSelectChange(items, "zone")}
                    />
                </form>
            </div>

            <p className="text-style-1">
                Copy and paste this code on your website where you want the ad to appear.
            </p>

            <div className="code">
                <div className="row">
                    <div className="label">
                        <img src={pixel} alt="" />
                        code
                    </div>
                    <CopyTextButton
                        text={trackAnEventScriptTag.value}
                        disabled={!trackAnEventScriptTag.value}
                        size="medium"
                    />
                </div>

                <div className="script-wrapper" style={{ fontSize: "12px" }}>
                    {trackAnEventScriptTag.isLoading ? (
                        "Loading..."
                    ) : trackAnEventScriptTag.value ? (
                        <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                            {trackAnEventScriptTag.value}
                        </SyntaxHighlighter>
                    ) : (
                        "Select a zone from the dropdown above to generate the invocation code"
                    )}
                </div>
            </div>
        </div>
    );
};
