import React, { useEffect, useState } from "react";
import { Select, Button, TableSkeleton, Pagination, Calendar } from "../../components";
import ReportsCalendar from "./ReportsCalendar";
import useFormatter from "../../hooks/useFormatter";
import localData from "../../localData";
import { useStateContext } from "../../stateContext";
import { useGlobalContext } from "../../context";

export default function ReportsTable() {
    const { requestedData, getLoadBlockchainData, isLoading, reportsState } = useGlobalContext();
    const {
        reportsPage,
        setReportsPage,
        reportsPageSize,
        setReportsPageSize, 
        reportsTotalPages,
        setReportsTotalPages,
        reportsItemsPerPage,
        setReportsItemsPerPage,
        reports,
        setReports,
        reportSelectionRange,
        setReportSelectionRange,
    } = reportsState;
    const { formatDate } = useFormatter();

    const { sendDollar } = localData.svgs;

    const handleCalendar = (range) => {
        const { startDate, endDate } = range;
        const dateFrom = formatDate(startDate);
        const dateTill = formatDate(endDate);
        localStorage.setItem("reportsSelectionRange", JSON.stringify(range));
        // localStorage.setItem("reportsSelectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
        setReportSelectionRange(range);
    };

    const handlePagination = (nextPage = 0) => {
        setReportsPage(nextPage);
        const indexOfLastItem = nextPage * reportsPageSize;
        const indexOfFirstItem = indexOfLastItem - reportsPageSize;
        const currentPageItems = requestedData.reportsData.slice(indexOfFirstItem, indexOfLastItem);
        setReports(currentPageItems);
    };

    const handlePageSizeChange = (activeItem) => {
        setReportsPageSize(activeItem.title);
        const tempReports = requestedData.reportsData.slice(0, activeItem.title);
        setReports(tempReports);
        setReportsPage(1);

        const reportsStates = JSON.parse(localStorage.getItem("reportsStates"));
        localStorage.setItem(
            "reportsStates",
            JSON.stringify({ ...reportsStates, pageSize: activeItem.title })
        );
    };

    useEffect(() => {
        const tempReports = requestedData.reportsData.slice(
            (reportsPage - 1) * reportsPageSize,
            reportsPage * reportsPageSize
        );
        setReports(tempReports);
    }, [requestedData.reportsData]);

    useEffect(() => {
        setReportsTotalPages(Math.ceil(requestedData.reportsData.length / reportsPageSize));
    }, [reports]);

    return (
        <>
            <Calendar
                className="reports-calendar"
                _selectionRange={reportSelectionRange}
                callback={handleCalendar}
            />
            <div className="scroll reports-scroll">
                {isLoading.reportsDataIsLoading ? (
                    <TableSkeleton />
                ) : (
                    <table className="table reports-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th text-start">Date</th>
                                <th className="table-th text-center">User Account Name</th>
                                <th className="table-th text-center">Commissions $USDT</th>
                                <th className="table-th text-center">Transaction ID</th>
                                {/* <th className="table-th text-center">
                                <div className="payment-icon">{sendDollar}</div>
                            </th> */}
                                <th className="table-th table-th-request">Request Payment</th>
                            </tr>
                        </thead>

                        <tbody>
                            {true ? (
                                <tr>
                                    <td colSpan="10">
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                reports.map((report, index) => (
                                    <ReportsRow key={index} report={report} index={index} />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.reportsDataIsLoading && !requestedData.reportsData.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
            {!reports.length ? (
                ""
            ) : (
                <div className="pagination-wrapper">
                    <Pagination
                        callback={handlePagination}
                        currentPage={reportsPage}
                        totalPages={reportsTotalPages}
                        isLoading={isLoading.reportsDataIsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: reportsItemsPerPage,
                                setItems: setReportsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                                callback: handlePageSizeChange,
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

const ReportsRow = ({ report }) => {
    return (
        <tr>
            <td className="table-td text-start">{report.date}</td>
            <td className="table-td text-center">{report.accountName}</td>
            <td className="table-td text-center">{report.commissions}</td>
            <td className="table-td text-center">{report.transactionID}</td>
            <td className="table-td table-td-request text-center">
                <Button
                    className="request-btn"
                    variant="contained"
                    size="xs"
                    color={`${report.isPaid ? "light" : "primary"}`}
                    name={`${report.isPaid ? "Paid" : "Request"}`}
                    disabled={report.isPaid}
                />
            </td>
        </tr>
    );
};
