import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import {
    Dropdown,
    Select,
    Button,
    Calendar,
    Loading,
    TableSkeleton,
    ChartSkeleton,
} from "../../../components";
import { v4 as uuidv4 } from "uuid";
import { BarChart, LineChart } from "../../../components";
import { useGlobalContext } from "../../../context";

import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";

export default function CampaignsGraph() {  
    const {
        requestedData: {
            userProfileData,
            campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
        },
        isLoading: { campaignDataIsLoading, userProfileIsLoading },
        formatNumber,
        getDateName,
        activeCampaign,
        isDarkModeEnabled,
        campaignsState,
    } = useGlobalContext();
    const { campaignSelectionRange } = campaignsState;

    const { formatCurrency, removeAlias, formatDateToMMDD } = useFormatter();

    const { user, textPreloader } = localData.images;
    const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

    const [graphBtns, setGraphBtns] = useState({
        impressions: {
            isActive: true,
            propertyName: "impressions",
            displayName: "impressions",
            color: "purple",
        },
        amount_spent: {
            isActive: true,
            propertyName: "amount_spent",
            displayName: "Earnings",
            color: "rgba(35, 149, 110,1)",
        },

        clicks: {
            isActive: false,
            propertyName: "clicks",
            displayName: "clicks",
            color: "rgba(21, 161, 197,1)",
        },
        CPM: {
            isActive: false,
            propertyName: "CPM",
            displayName: "CPM",
            color: "rgba(6, 23, 38,1)",
        },
        CPC: {
            isActive: false,
            propertyName: "CPC",
            displayName: "CPC",
            color: "rgba(249, 57, 57,1)",
        },
    });

    const handleActiveChartBtn = (e) => {
        const name = e.currentTarget.dataset.name;
        setGraphBtns({
            ...graphBtns,
            [name]: {                
                ...graphBtns[name],
                isActive: !graphBtns[name].isActive,
            },
        });
    };

    const [activeGraphCampaign, setActiveGraphCampaign] = useState(null);
    const [filteredGraphData, setFilteredGraphData] = useState([]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const filterChartData = () => {
        if (!currentOrganizationCampaigns.length) {
            setFilteredGraphData([]);
            return;
        }
        if (!activeGraphCampaign) return;

        const { progressionData } = activeGraphCampaign;
        if (!progressionData) return;
        const { startDate, endDate } = campaignSelectionRange;
        const array = [];

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dateString = formatDate(date);
            const dataForDate = progressionData[dateString];

            if (dataForDate) {
                const obj = {};
                obj.name = getDateName({ date: dateString });

                if (graphBtns.impressions.isActive) obj.impressions = dataForDate.impressions || 0;
                if (graphBtns.amount_spent.isActive) obj.amount_spent = dataForDate.amount_spent || 0;
                if (graphBtns.clicks.isActive) obj.clicks = dataForDate.clicks || 0;
                if (graphBtns.CPM.isActive) obj.CPM = dataForDate.cpm || 0;
                if (graphBtns.CPC.isActive) obj.CPC = dataForDate.cpc || 0;

                array.push(obj);
            } else {
                // const obj = {
                //     name: getDateName({ date: dateString }),
                //     impressions: graphBtns.impressions.isActive ? 0 : undefined,
                //     amount_spent: graphBtns.amount_spent.isActive ? 0 : undefined,
                //     clicks: graphBtns.clicks.isActive ? 0 : undefined,
                //     CPM: graphBtns.CPM.isActive ? 0 : undefined,
                //     CPC: graphBtns.CPC.isActive ? 0 : undefined,
                // };

                // array.push(obj);

                const obj = {};
                obj.name = formatDateToMMDD(date);
                obj.empty = 0;
                array.push(obj);
            }
        }
        setFilteredGraphData(array);
    };

    useEffect(() => {
        if (!currentOrganizationCampaigns.length) return;
        if (!activeCampaign) return;
        const tempActiveGraphCampaign = currentOrganizationCampaigns.find(
            (item) => item.id === activeCampaign.id
        );
        setActiveGraphCampaign(tempActiveGraphCampaign);
    }, [activeCampaign, currentOrganizationCampaigns]);

    useEffect(() => {
        filterChartData();
    }, [activeGraphCampaign, graphBtns, currentOrganizationCampaigns]);

    const [isLineChartActive, setIsLineChartActive] = useState(true);

    return (
        <div className="campaigns-graph shadow">
            <div className="campaigns-graph-inner">
                <div className="graph">
                    <div className="graph-header">
                        <img src="https://firebasestorage.googleapis.com/v0/b/web3-marketing-hub.appspot.com/o/images%2Fuser.png?alt=media&token=55bb1159-4dc8-4a03-abf4-b2ba80ed52f9" alt="" />
                        <p className="graph-text dark-mode-style">
                            <span>Welcome to Blockchain-Ads</span>
                            <strong className="dark-mode-style">
                                {userProfileIsLoading ? (
                                    <img src={textPreloader} className="text-preloader" />
                                ) : (
                                    userProfileData.contact_name
                                )}
                            </strong>
                        </p>
                    </div>

                    <div className="graph-body">
                        <h2
                            className="graph-title"
                            data-tooltip-id="tooltip-graph-title"
                            data-tooltip-content={
                                activeGraphCampaign && removeAlias(activeGraphCampaign.name)
                            }
                        > <span className="prefix">Website:</span>
                            {campaignDataIsLoading
                                ? ""
                                : activeGraphCampaign &&
                                   removeAlias(activeGraphCampaign.name)}
                            <Tooltip id="tooltip-graph-title" className="custom-tooltip" />
                        </h2>
                        <div className="division">
                            <div className="division-icon btn-icon">{eye}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Estimated Earnings</h6>
                                <div 
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        // formatCurrency(dashboardSummaryData?.totalAdSpend, 2)
                                        formatCurrency(activeCampaign?.amount_spent, 2)
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{transactions}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Clicks</h6>
                                <div
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        // formatNumber(dashboardSummaryData?.totalClicks?.toFixed(0) || "0")
                                        formatNumber(activeCampaign?.clicks?.toFixed(0) || "0")
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{coins}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Impressions</h6>
                                <div
                                    className={`division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    } `}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        formatNumber(
                                            // dashboardSummaryData?.totalImpressions?.toFixed(0) || "0"
                                            activeCampaign?.impressions.toFixed(0) || "0"
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider"></div>

                <div className={`chart-wrapper `}>
                    {campaignDataIsLoading && (
                        <div className="loader">
                            <div className="message">Loading...</div>
                            <img src={textPreloader} />
                        </div>
                    )}
                    {/* <div className="row row-top">
                        <Dropdown
                            {...{
                                title: dateRange,
                                variant: "text",
                                color: "dark",
                                className: "calendar-dropdown",
                                isOpen: isCalendarOpen,
                                setIsOpen: setIsCalendarOpen,
                                size: "sm",
                            }}
                        >
                            <Calendar
                                setIsOpen={setIsCalendarOpen}
                                {...{
                                    dateRange,
                                    setDateRange,
                                    selectionRange,
                                    setSelectionRange,
                                    setDefaultSelectionRange,
                                    callback: callback,
                                }}
                            />
                        </Dropdown>
                    </div> */}
                    <div className="row row-bottom">
                        <div className="col col-left btn-group">
                            <Button
                                variant="circle"
                                icon={lineChart}
                                size="sm"
                                color={isLineChartActive ? "dark" : "light"}
                                onClick={() => setIsLineChartActive(true)}
                            />
                            <Button
                                variant="circle"
                                icon={barChart}
                                size="sm"
                                color={isLineChartActive ? "light" : "dark"}
                                onClick={() => setIsLineChartActive(false)}
                            />
                        </div>
                        <div className="col col-right btn-group">
                            {Object.values(graphBtns).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`chart-btn ${item.isActive ? "active" : ""}`}
                                        data-name={item.propertyName}
                                        onClick={handleActiveChartBtn}
                                    >
                                        <div
                                            className="list-style"
                                            style={{ backgroundColor: item.color }}
                                        ></div>
                                        {item.displayName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* {isLineChartActive ? ( */}
                    <LineChart
                        data={filteredGraphData}
                        // defaultData={[
                        //     {
                        //         empty: 0,
                        //         name: "",
                        //     },
                        //     // {
                        //     //     empty: 0,
                        //     //     name: "Campaign progression is empty",
                        //     // },
                        // ]}
                        lines={Object.values(graphBtns)}
                        isLoading={campaignDataIsLoading}
                    />
                    {/* ) : (
                        <BarChart
                            data={filteredGraphData}
                            defaultData={[
                                {
                                    empty: 0,
                                    name: "Campaign progression is empty",
                                },
                            ]}
                            bars={Object.values(graphBtns)}
                            isLoading={campaignDataIsLoading}
                        />
                    )} */}
                </div>
            </div>
        </div>
    );
}
