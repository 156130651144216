import React, { useState, useEffect } from "react";
import { Loading, Button } from "../../../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import { useGlobalContext } from "../../../../context";
import useFetch from "../../../../hooks/useFetch";
import localData from "../../../../localData";
import useFormatter from "../../../../hooks/useFormatter";
import { Link } from "react-router-dom";
 
export default function CampaignInfo({ data, isLoading }) {
    const { placeholder, analysis } = localData.images;
    const { arrowRight } = localData.svgs;
    const { formatCurrency, removeAlias } = useFormatter();

    const { requestedData } = useGlobalContext();

    const getArrayItems = (property = "", withTooltip = false) => {
        if (!data) return <span className="text-danger error-text">No Data</span>;
        if (!Object.keys(data.extraData).length) return <span className="  empty-text">...</span>;
        if (!data.extraData[property]) return <span className=" empty-text">...</span>;
        if (!Array.isArray(data.extraData[property]))
            return <span className="text-danger error-text">Not an array property.</span>;
        return !data.extraData[property].length ? (
            <span className=" empty-text">...</span>
        ) : (
            <>
                <p
                    className="col value"
                    data-tooltip-id={`tooltip-campaign-${[property]}`}
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        data.extraData[property].map((item, index) => (
                            <span key={index}>
                                {item}
                                {index !== data.extraData[property].length - 1 && ", "}{" "}
                            </span>
                        ))
                    )}
                >
                    {/* <div className="values"> */}
                    {data.extraData[property].map((item, index) => (
                        <span key={index}>
                            {item}
                            {index !== data.extraData[property].length - 1 && ", "}{" "}
                        </span>
                    ))}
                    {/* </div> */}
                </p>
                <Tooltip
                    id={`tooltip-campaign-${[property]}`}
                    className="custom-tooltip max-w-300 whitespace-normal"
                />
            </>
        );
    };

    return (
        <div className="campaign-info">
            <div className="campaign-info-body">
                {isLoading ? (
                    <Loading />
                ) : Object.keys(data).length === 0 ? (
                    <div className="no-data"> No Data To Show!</div>
                ) : (
                    <>
                        <div className="wrapper campaign-details">
                            <h2 className="title">Website Info</h2>
                            <div className="row">
                                <p className="col name">Website name:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-name"
                                    data-tooltip-content={removeAlias(data.name)}
                                >
                                    {removeAlias(data.name) || <span className="empty-text">...</span>}
                                </p>
                                {data.name && (
                                    <Tooltip id="tooltip-campaign-name" className="custom-tooltip" />
                                )}
                            </div>

                            <div className="row">
                                <p className="col name">Target URL:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-url"
                                    data-tooltip-content={data.website}
                                >
                                    {data.website || <span className="empty-text">...</span>}
                                </p>
                                {data.website && (
                                    <Tooltip id="tooltip-campaign-url" className="custom-tooltip" />
                                )}
                            </div>
                            <div className="row">
                                <p className="col name">Smart Contract:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-smart-contract-address"
                                    data-tooltip-content={data.mneumonic}
                                >
                                    {data.mneumonic || (
                                        <span className="empty-text">...</span>
                                    )}
                                </p>
                                {data.mneumonic && (
                                    <Tooltip
                                        id="tooltip-campaign-smart-contract-address"
                                        className="custom-tooltip"
                                    />
                                )}
                            </div>
                            {/* <div className="row">
                            <p className="col name">Chain Targeting :</p>
                            <p
                                className="col value"
                                data-tooltip-id="tooltip-campaign-chain-targeting"
                                data-tooltip-content={data.extraData.chainTargeting}
                            >
                                {(Array.isArray(data.extraData.chainTargeting)
                                    ? getArrayItems("chainTargeting")
                                    : data.extraData.chainTargeting) || (
                                    <span className="empty-text">...</span>
                                )}
                             
                            </p>
                            {data.extraData.chainTargeting && (
                                <Tooltip id="tooltip-campaign-chain-targeting" className="custom-tooltip" />
                            )}
                        </div> */}
                            {/* <div className="row">
                                <p className="col name">Chain Targeting:</p>
                                {getArrayItems("chainTargeting")}
                            </div> */}
                            {/* <div className="row">
                            <p className="col name">Chain Targeting :</p>
                            <p
                                className="col value"
                                data-tooltip-id="tooltip-campaign-chain-targeting"
                                data-tooltip-content={data.extraData.chainTargeting}
                            >
                                {data.extraData.chainTargeting || <span className="empty-text">...</span>}
                            </p>
                            {data.extraData.chainTargeting && (
                                <Tooltip id="tooltip-campaign-chain-targeting" className="custom-tooltip" />
                            )}
                        </div> */}
                            <div className="row">
                                <p className="col name">Max CPC :</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-CPM"
                                    data-tooltip-content={0}
                                >
                                    {0 || (
                                        <span className="empty-text">...</span>
                                    )}
                                </p>
                                {/* {data.max_cpc.toString() && (
                                    <Tooltip id="tooltip-campaign-CPM" className="custom-tooltip" />
                                )} */}
                            </div>
                            {/* <div className="row">
                                <p className="col name">Budget:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-total_amount"
                                    data-tooltip-content={formatCurrency(data.total_amount, 2)}
                                >
                                    {formatCurrency(data.total_amount, 2) || (
                                        <span className="empty-text">...</span>
                                    )}
                                </p>
                                {data.total_amount.toString() && (
                                    <Tooltip id="tooltip-campaign-total_amount" className="custom-tooltip" />
                                )}
                            </div> */}
                            <div className="row">
                                <p className="col name">Creation Date:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-start-date"
                                    data-tooltip-content={data.updated}
                                >
                                    {data.updated || <span className="empty-text">...</span>}
                                </p>
                                {data.updated?.toString() && (
                                    <Tooltip id="tooltip-campaign-start-date" className="custom-tooltip" />
                                )}
                            </div>
                            {/* <div className="row">
                                <p className="col name">End Date:</p>
                                <p
                                    className="col value"
                                    data-tooltip-id="tooltip-campaign-end-date"
                                    data-tooltip-content={data.endDate || data.end_date}
                                >
                                    {data.endDate || data.end_date || <span className="empty-text">...</span>}
                                </p>
                                {data.endDate ||
                                    (data.end_date?.toString() && (
                                        <Tooltip id="tooltip-campaign-end-date" className="custom-tooltip" />
                                    ))}
                            </div> */}
                        </div>

                        {/* <div className="wrapper targeting-details">
                            <h2 className="title">Targeting</h2>
                            <div className="row">
                                <p className="col name">Category:</p>
                                {getArrayItems("category", true)}
                            </div>
                            <div className="row">
                                <p className="col name">Web3 Audiences :</p>
                                {getArrayItems("web3Audience", true)}
                            </div>

                            <div className="row">
                                <p className="col name">Wallet Targeting :</p>
                                {getArrayItems("walletTargeting")}
                            </div>
                            <div className="row">
                                <p className="col name">Device Type:</p>
                                {getArrayItems("deviceType")}
                            </div>
                            <div className="row">
                                <p className="col name">Operative System:</p>
                                {getArrayItems("operativeSystem")}
                            </div>
                            <div className="row">
                                <p className="col name">Browser:</p>
                                {getArrayItems("browser")}
                            </div>
                        </div> */}

                        {/* <div className="wrapper ads-details">
                            <h2 className="title">Ads</h2>
                            <div className="table-wrapper">
                                <div className="table-scroll">
                                    <table className="ads-table">
                                        <thead>
                                            <tr>
                                                <th className="ads-th-name">Name</th>
                                                <th className="ads-th-image">Image</th>
                                                <th className="ads-th-status">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {!data.creatives || !data.creatives.length ? (
                                                <tr>
                                                    <td>
                                                        <div className="no-data">No Data To Show!</div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                data.creatives.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="ads-td-title">
                                                                <div
                                                                    className="content"
                                                                    data-tooltip-id="tooltip-campaign-creation-date"
                                                                    data-tooltip-content={item.title}
                                                                >
                                                                    {item.title || (
                                                                        <span className="empty-text">
                                                                            ...
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <Tooltip
                                                                    id="tooltip-campaign-creation-date"
                                                                    className="custom-tooltip"
                                                                />
                                                            </td>
                                                            <td className="ads-td-image">
                                                                <div
                                                                    className="content"
                                                                    data-tooltip-id="tooltip-campaign-image"
                                                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                                        <div className="image-wrapper">
                                                                            <img src={item.image} />
                                                                        </div>
                                                                    )}
                                                                    data-tooltip-float={true}
                                                                >
                                                                    {item.image || (
                                                            <span className="empty-text">...</span>
                                                            // <img src={item.image} alt="" />
                                                        )}

                                                                    <img src={item.image} />
                                                                    <img src={item.image} />
                                                                </div>
                                                            </td>

                                                            <td className="ads-td-status">
                                                                <div
                                                                    className="content"
                                                                    data-tooltip-id="tooltip-campaign-status"
                                                                    data-tooltip-content={item.status}
                                                                >
                                                                    {item.status || (
                                                                        <span className="empty-text">
                                                                            ...
                                                                        </span>
                                                                    )}
                                                                    <Tooltip
                                                                        id="tooltip-campaign-status"
                                                                        className="custom-tooltip"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <Tooltip id="tooltip-campaign-image" className="custom-tooltip image-tooltip" />
                        </div> */}
                    </>
                )}
            </div>
            <br />
            {/* <hr style={{ borderTop: 0}}/> */}
            <div className="campaign-info-footer">
                <div
                    style={{ display: "inline-block" }}
                    className="content"
                    data-tooltip-id="tooltip-campaign-statistics"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <p>
                            "This campaign is running on Custom Inventory.
                            <br /> For detailed statistics, please contact the Blockchain-Ads team."
                        </p>
                    )}
                >
                    <Link
                        //to={`/statistics/${data.id}`}
                        style={{
                            pointerEvents:
                                requestedData.userProfileData.currentOrganization?.id ==
                                    "d2a4a5c0-05f3-4673-93bd-ec592e63346d" && data.id == 8256340
                                    ? "none"
                                    : "",
                        }}
                    >
                        <Button
                            name="Detailed Statistics"
                            variant="contained"
                            size="sm"
                            endIcon={arrowRight}
                            disabled={ true  }
                        />
                    </Link>
                </div>
                {requestedData.userProfileData.currentOrganization?.id ==
                    "d2a4a5c0-05f3-4673-93bd-ec592e63346d" &&
                    data.id == 8256340 && (
                        <Tooltip id="tooltip-campaign-statistics" className="custom-tooltip image-tooltip" />
                    )}
                <img src={analysis} alt="" />
            </div>
        </div>
    );
}
