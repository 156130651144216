import React, { useState, useEffect, useRef } from "react";
import {
    TableSkeleton,
    Select,
    Button,
    Dropdown,
    CampaignDetailsPopup,
    Popup,
    Pagination,
    CampaignsCalendar,
    Calendar,
    Modal,
} from "../../../components"; 
import { v4 as uuidv4 } from "uuid";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext.jsx";
import { useCampaignsContext } from "../../../campaignsContext.jsx";
import { auth } from "../../../config/firebase.js";
import useFetch from "../../../hooks/useFetch.js";
import CampaignInfo from "./campaign-info/CampaignInfo.jsx";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter.jsx";
import CampaignsStatiscticsCSV from "../statistics-csv/CampaignsStatiscticsCSV.jsx";

let isStatExist = false; 
  
export default function CampaignsTable() { 
    const [campaignData, setCampaignData] = useState([]);

    const {  
        getLoadCampaignData, 
        isLoading, 
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        campaignsState, 
        ensureTokenValid,
        isTokenExpired,
        updateTokens,
         refreshAccessToken, 
        } = useGlobalContext();
    const {
        campaignsPage,
        setCampaignsPage,
        campaignsPageSize,
        setCampaignsPageSize,
        campaignsTotalPages,
        setCampaignsTotalPages,
        campaignsItemsPerPage,
        setCampaignsItemsPerPage,
        campaignSelectionRange,
        setCampaignSelectionRange,
        handleCalendar,
    } = campaignsState;

    // const { isCampaignsCalendarOpen, setIsCampaignsCalendarOpen, dateRange, selectionRange } =
    //     useCampaignsContext();

    const [organizationId, setOrganizationId] = useState(null);

    const campaignTableRef = useRef(null);

    const handlePagination = (nextPage = 0) => {
        setCampaignsPage(nextPage);
        
    };

    useEffect(() => {
        setCampaignsPageSize(campaignsItemsPerPage.find((item) => item.isSelected).title);
    }, [campaignsItemsPerPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    }, [campaignsPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, 1, campaignsPageSize);

        const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        localStorage.setItem(
            "campaignsStates",
            JSON.stringify({
                ...campaignsStates,
                pageSize: campaignsPageSize,
            })
        );
    }, [campaignsPageSize]);

    useEffect(() => {
       // const id = requestedData.userProfileData.currentOrganization.id;
       // if (!id) return;
       // setOrganizationId(id);
        // if(requestedData.campaignData.isRequested) return
        // getLoadCampaignData(id, 1, 10);
    }, []);

    useEffect(() => {
        // const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
        // if (!campaigns.length) return;
        // const campaignsTotalPages = Math.ceil(requestedData.campaignData.dataSize / campaignsPageSize);
        // setCampaignsTotalPages(campaignsTotalPages);
        const fetchCampaignData = async () => {
            const user = localStorage.getItem("user");
            const parsedUser = JSON.parse(user);

            try {
             
                //await ensureTokenValid(); // Ensure the token is valid before making the request
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` + parsedUser.access_token, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "user_id": parsedUser.user_id
                    }),
                });
                const result = await response.json();
                setCampaignData(result.result); // Store fetched data in state

                setCampaignsTotalPages(Math.ceil(result.result.length / campaignsPageSize));
               // console.log(campaignData);
               // console.log(result.result);
               console.log(result.result);

                
            } catch (error) {
                
                if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                    await updateTokens();
                    const user = await JSON.parse(localStorage.getItem("user"));
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` + user.access_token, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "user_id": parsedUser.user_id
                        }),
                    });
                    const result = await response.json();
                    setCampaignData(result.result); // Store fetched data in state
                   
                   // localStorage.removeItem("user"); // Remove user from localStorage
                   // window.location.href = '/sign-in'; // Redirect to the login page
                    
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                }
            }   
        
                
        
            
        };

        fetchCampaignData();
    }, []);

    const { settings, eyeSolid } = localData.svgs;
    const { textPreloader } = localData.images;

    // const formatDate = (date) => {
    //     const day = date.getDate().toString().padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear().toString();
    //     return `${day}/${month}/${year}`;
    // };

    // const handleCalendar = (range) => {
    //     const { startDate, endDate } = range;
    //     const dateFrom = formatDate(startDate);
    //     const dateTill = formatDate(endDate);
    //     localStorage.setItem("selectionRange", JSON.stringify(range));
    //     localStorage.setItem("selectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
    //     campaignsPage !== 1
    //         ? setCampaignsPage(1)
    //         : getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    //     setCampaignSelectionRange(range);
    // };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className="campaigns-table-wrapper" ref={campaignTableRef}>
            <div className="row"> 
                {/* <h2 className="display-2">My Websites</h2> */}
                {/* <Dropdown
                    {...{
                        title: dateRange,
                        variant: "text",
                        color: "dark",
                        className: "calendar-dropdown",
                        isOpen: isCampaignsCalendarOpen,
                        setIsOpen: setIsCampaignsCalendarOpen,
                        size: "sm",
                    }}
                >
                    <CampaignsCalendar
                        setIsOpen={setIsCampaignsCalendarOpen}
                        callback={handleCalendar}
                        {
                            ...{
                                // callback: callback,
                            }
                        }
                    />
                </Dropdown> */}
                <CampaignsStatiscticsCSV />
                <Calendar
                    className="reports-calendar"
                    _selectionRange={campaignSelectionRange}
                    callback={handleCalendar}
                />
            </div>
            <br />
            <div className={`scroll shadow }`} style={{ padding: 0 }}>
                {isLoading.campaignDataIsLoading ? (
                    <TableSkeleton message="Loading..." icon={textPreloader} />
                ) : (
                    <table id="campaigns-table" className="table campaigns-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-details text-center">
                                    {/* <Popup
                                        position="right"
                                        toggler="?"
                                        content="See preview of the campaign details."
                                    /> */}
                                    <div
                                        className="details-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-details"
                                        data-tooltip-content={`See preview of the campaign details.`}
                                    >
                                        {eyeSolid}
                                    </div>
                                </th>

                                <th className="table-th-name text-start" style={{ width: "20%" }}>
                                    <span
                                        data-tooltip-id="tooltip-table-name"
                                        data-tooltip-content={`The name of your campaign.`}
                                    >
                                        Website
                                    </span>
                                </th>
                                <th
                                    className="table-th-settings text-center"
                                    // style={{ width: "20%" }}
                                    style={{ width: "0" }}
                                    // title="Activate or Pause your campaign"
                                >
                                    <div
                                        className="settings-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-settings"
                                        data-tooltip-content={`Activate or Pause your campaign.`}
                                    >
                                        {settings}
                                    </div>
                                </th>

                                {/* <th className="table-th-status text-center" style={{ width: "0" }}>
                                    <span
                                        data-tooltip-id="tooltip-table-status"
                                        data-tooltip-content={`The current status of your campaign.`}
                                    >
                                        Status
                                    </span>
                                </th> */}
                                <th className="table-th-impressions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                    >
                                        Impressions
                                    </span>
                                </th>
                                <th className="table-th-impressions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                    >
                                        Impressions RPM
                                    </span>
                                </th>
                                <th className="table-th-clicks text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                    >
                                        Clicks
                                    </span>
                                </th>
                            
                                <th className="table-th-ad-spend text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                    >
                                         Page RPM
                                    </span>
                                </th>
                           
                                <th className="table-th-conversions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-conversions"
                                        data-tooltip-content={`Total amount of the conversions.`}
                                    >
                                      
                                       Estimated<br/> earnings*
                                    </span>
                                    <Tooltip id="tooltip-table-conversions" className="custom-tooltip" />
                                </th>
                                <th className="table-th-txn-value text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-txn-value"
                                        data-tooltip-content={`Total amount of the transaction.`}
                                    >
                                        Active view <br/> viewable
                                    </span>
                                    <Tooltip id="tooltip-table-txn-value" className="custom-tooltip" />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!Array.isArray(campaignData) || campaignData.length === 0 ? (
                                <tr style={{ pointerEvents: "none" }}>
                                    <td colSpan="10">
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                campaignData.map((item, index) => (
                                    <CampaignRow key={index} item={item} index={index} />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.campaignDataIsLoading &&
                    !campaignData.length && (
                        <div className="no-data-cover"></div>
                    )}
            </div>
            {!Array.isArray(campaignData) || campaignData.length === 0 ? (
                ""
            ) : (
                <div className={`pagination-wrapper  ${isLoading.campaignDataIsLoading ? "on-hold" : ""}`}>
                    <Pagination
                        callback={handlePagination}
                        currentPage={campaignsPage}
                        totalPages={campaignsTotalPages}
                        isLoading={isLoading.campaignDataIsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: campaignsItemsPerPage,
                                setItems: setCampaignsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

// ===========================================================================================================
// const CAMPAIGN_STATUS = Object.freeze({
//     "Draft": 1,
//     "Moderation": 2,
//     "Rejected": 3,
//     "Ready": 4,
//     "Test run": 5,
//     "Working": 6,
//     "Paused": 7,
//     "Stopped": 8,
//     "Completed": 9,
//   });

// const showStopIcon = ["Working",'Paused · Daily Budget', "Moderation"];
// const showPlayIcon = [ "Stopped", "Draft", "Ready", "Test run"];

const showStopBtn = [6, 7, 2];
const showPlayBtn = [8, 1, 4, 5];

const CampaignRow = ({ item, index }) => {
    const { formatNumber, formatCurrency } = useFormatter();
    const {
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        campaignWizardStatus,
        setCampaignWizardStatus,
        errorAlert,
        successAlert,
        campaignState,
        setCampaignState,
        requestedData,
        activeCampaign,
        setActiveCampaign,
        activeInfoId,
        setActiveInfoId,
        isDarkModeEnabled,
        getLoadCampaignData,
        setCreatives,
        campaignsState,
        conversionOptions: { advertiserPixelStats },
    } = useGlobalContext();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { arrowDown, arrowUp, settings, play, stop, penV2, trashV2, close } = localData.svgs;
    const { removeAlias, formatDate, addCommas } = useFormatter();

    // const [isPaused, setIsPaused] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState({
        isStopped: false,
        status: "unknown",
        statusNumber: null,
    });

    const [campaignBtns, setCampaignBtns] = useState({
        playBtn: {
            isReadOnly: true,
            message: "",
            isPlayBtnShown: false,
        },
        stopBtn: {
            isReadOnly: true,
            message: "",
            isStopBtnShown: false,
        },
        editBtn: {
            isReadOnly: true,
            message: "",
        },
        deleteBtn: {
            isReadOnly: true,
            message: "",
        },
    });

    useEffect(() => {
        const isEditBtnShown = true;
        const isEditBtnReadOnly = [].includes(campaignDetails.statusNumber);

        const isDeleteBtnShown = true;
        const isDeleteBtnReadOnly = [1].includes(campaignDetails.statusNumber);

        const isStopBtnShown = showStopBtn.includes(campaignDetails.statusNumber);
        const isStopBtnReadOnly = false;

        const isPlayBtnShown = showPlayBtn.includes(campaignDetails.statusNumber);
        const isPlayBtnReadOnly = false;

        // const isLowBalance = requestedData.userProfileData.currentOrganization.balance < 330;
        const isLowBalance = false;

        const message = (
            <span>
                Can't be modified <br /> due to it's {item.status} status
            </span>
        );

        setCampaignBtns({
            ...campaignBtns,

            playBtn: {
                isReadOnly: isPlayBtnReadOnly || isLowBalance,
                message: isPlayBtnReadOnly ? message : "Insufficient balance",
                isPlayBtnShown,
            },
            stopBtn: {
                isReadOnly: isStopBtnReadOnly || isLowBalance,
                message: isStopBtnReadOnly ? message : "Insufficient balance",
                isStopBtnShown,
            },
            editBtn: {
                isReadOnly: isEditBtnReadOnly,
                message: message,
                isEditBtnShown,
            },
            deleteBtn: {
                isReadOnly: isDeleteBtnReadOnly,
                message: message,
                isDeleteBtnShown,
            },
        });
    }, [campaignDetails]);

    useEffect(() => {
        setCampaignDetails((prev) => ({
            ...prev,
            isStopped: item.isPaused,
            status: item.status,
            statusNumber: item.statusNumber,
        }));
    }, []);

    // FETCH
    const [campaignInfoData, setCampaignInfoData] = useState({});
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const { playOrPauseCampaign, deleteCampaign, sumTransactionQuantities } = useFetch();

    // PLAY OR PAUSE CAMPAIGN
    const getPlayOrPauseCampaign = async (campaignId, toPlayCampaign) => {
        // if (!auth.currentUser) return;
        setIsPlayPauseLoading(true);

        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            campaignId,
            toPlayCampaign,
        };
        try {
            const data = await playOrPauseCampaign(() => {}, temRaw);
            const { isPaused, status = "unknown", statusNumber = null } = data.res_data;
            setCampaignDetails((prev) => ({
                ...prev,
                isStopped: isPaused,
                status: status.toLowerCase(),
                statusNumber,
            }));
            console.log(data, "=playOrPauseCampaign= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=playOrPauseCampaign= request error");
        }
        setIsPlayPauseLoading(false);
    };

    const handleDeleteCampaign = async (campaignId) => {
        // setIsDeleteLoading(true);

        // const temRaw = {
        //     uuid: auth.currentUser.uid,
        //     organizationID: requestedData.userProfileData.currentOrganization.id,
        //     campaignId,
        // };
        // try {
        //     const data = await deleteCampaign(() => {}, temRaw);
        //     successAlert("Campaign deleted successfully");
        //     getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, 1, 10);
        //     console.log(data, "=deleteCampaign= request");
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=deleteCampaign= request error");
        // }
        // setIsDeleteLoading(false);
        alert('delete triggered')
    };

    const [displayDeleteModal, setDisplayDeleteModal] = useState("none");

    const DeleteCampaignModal = ({ setIsOpen }) => {
        const closeModal = () => {
            setIsOpen(false);
        };
        return (
            <>
                <div className="modal-header">
                    <Button
                        className="btn-close"
                        variant="circle"
                        color="dark"
                        size="sm"
                        icon={close}
                        onClick={closeModal}
                    />
                </div>
                <div className="modal-header" style={{ padding: "0px 1rem 20px" }}>
                    <h5 className="modal-title">Delete this campaign?</h5>
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="light"
                        style={{ marginRight: "10px" }}
                        onClick={closeModal}
                        name="cancel"
                        size="sm"
                    />
                    <Button
                        variant="contained"
                        color="danger"
                        onClick={() => {
                            closeModal();
                            handleDeleteCampaign(item.id);
                        }}
                        name="Confirm"
                        size="sm"
                    />
                </div>
            </>
        );
    };

    const [txnValue, setTxnValue] = useState("");
    const [txnValueIsLoading, setTxnValueIsLoading] = useState(false);

    // const getTxnValue = async ({ smartContractAddress = "" }) => {
    //     setTxnValueIsLoading((prev) => true);
    //     const endDate = new Date(); // today
    //     const startDate = new Date(endDate);
    //     startDate.setDate(endDate.getDate() - 6); // 7 days ago

    //     const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
    //         dateFrom: formatDate(startDate),
    //         dateTill: formatDate(endDate),
    //     };
    //     const tempRaw = {
    //         // uuid: auth.currentUser.uid,
    //         // chainNetwork,
    //         // smartContractAddress,
    //         // page,
    //         // pageSize,
    //         // order: "desc",
    //         smartContractAddress: [smartContractAddress],
    //         startDate: selectionRangeFormatted.dateFrom,
    //         endDate: selectionRangeFormatted.dateTill,
    //     };
    //     try {
    //         const data = await sumTransactionQuantities(() => {}, tempRaw);
    //         // console.log(data, "=sumTransactionQuantities= request");

    //         setTxnValue(data);
    //     } catch (err) {
    //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //         console.error(err, "=sumTransactionQuantities= request error");
    //     }
    //     setTxnValueIsLoading((prev) => false);
    // };

    // useEffect(() => {
    //     getTxnValue({ smartContractAddress: item.extraData.smartContractAddress });
    // }, []);

    return (
        <>
            <tr
                className={`table-row ${item.id === activeCampaign?.id ? "active" : ""} ${
                    isDarkModeEnabled ? "dark-mode" : ""
                }`}
                onClick={() => {
                    setActiveCampaign(item);
                }}
            >
                <td className="table-td-hush">
                    <Button
                        variant="circle"
                        size="sm"
                        color="secondary"
                        // icon={activeRow === index ? eyeSlash : eye}
                        icon={activeInfoId === item.id ? arrowUp : arrowDown}
                        onClick={(e) => {
                            e.stopPropagation();
                            // setActiveRow(activeRow === index ? null : index);
                            setActiveInfoId(activeInfoId === item.id ? null : item.id);
                        }}
                    />
                </td>
                <td className="table-td-name text-start">
                    <span>{removeAlias(item.name)}</span>
                </td>
                <td className="table-td-settings text-center">
                    <div onClick={(e) => e.stopPropagation()} className="table-td-settings-wrapper">
                        {/* <div
                            className={`play-pause-btn-wrapper  ${
                                isPlayPauseLoading ? "on-hold spin-loading" : ""
                            }`}
                        >
                            {campaignBtns.playBtn.isPlayBtnShown && (
                                <div
                                    data-tooltip-id="play-btn-tooltip"
                                    data-tooltip-html={
                                        campaignBtns.playBtn.isReadOnly
                                            ? ReactDOMServer.renderToStaticMarkup(
                                                  campaignBtns.playBtn.message
                                              )
                                            : ""
                                    }
                                >
                                    <Button
                                        tooltipContent={"Play"}
                                        variant="circle"
                                        color={"success"}
                                        size="sm"
                                        icon={play}
                                        onClick={() => {
                                            getPlayOrPauseCampaign(item.id, true);
                                        }}
                                        className="play-pause"
                                        disabled={campaignBtns.playBtn.isReadOnly}
                                    />
                                    <Tooltip id="play-btn-tooltip" className="custom-tooltip" />
                                </div>
                            )}
                            {campaignBtns.stopBtn.isStopBtnShown && (
                                <div
                                    data-tooltip-id="stop-btn-tooltip"
                                    data-tooltip-html={
                                        campaignBtns.stopBtn.isReadOnly
                                            ? ReactDOMServer.renderToStaticMarkup(
                                                  campaignBtns.stopBtn.message
                                              )
                                            : ""
                                    }
                                >
                                    <Button
                                        tooltipContent={"Stop"}
                                        variant="circle"
                                        color={"danger"}
                                        size="sm"
                                        icon={stop}
                                        onClick={() => {
                                            getPlayOrPauseCampaign(item.id, false);
                                        }}
                                        className="play-pause"
                                        disabled={campaignBtns.stopBtn.isReadOnly}
                                    />
                                    <Tooltip id="stop-btn-tooltip" className="custom-tooltip" />
                                </div>
                            )}
                        </div> */}
                        <div
                            className={`edit-btn-wrapper ${false ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="edit-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.editBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.editBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Edit"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={penV2}
                                onClick={() => {
                                    setCampaignState({
                                        ...campaignState,
                                        campaignId: item.id,
                                        creatorEmail: requestedData.userProfileData.userEmail,
                                        campaignInfo: {
                                            ...campaignState.campaignInfo,
                                            campaignName: item.name,
                                            targetURL: item.extraData.targetURL,
                                            smartContractAddress: item.extraData.smartContractAddress,
                                            campaignType: item.extraData.campaignType || [],
                                            budget: item.total_amount.toString(),
                                            maxCPC: item.max_cpc,
                                            startDate: item.creation_date,
                                            endDate: item.endDate || item.end_date || "",
                                            // endDate: '',
                                            category: item.extraData.category || [],
                                            web3Audience: item.extraData.web3Audience || [],
                                            chainTargeting: item.extraData.chainTargeting || [],
                                            walletTargeting: item.extraData.walletTargeting || [],
                                            deviceType: item.extraData.deviceType || [],
                                            operativeSystem: item.extraData.operativeSystem || [],
                                            browser: item.extraData.browser || [],
                                            countriesTargeting: item.extraData.countriesTargeting || [
                                                "ad",
                                                "af",
                                                "ag",
                                                "ai",
                                                "al",
                                                "am",
                                                "an",
                                                "ao",
                                                "aq",
                                                "ar",
                                                "as",
                                                "at",
                                                "au",
                                                "aw",
                                                "ax",
                                                "az",
                                                "ba",
                                                "bb",
                                                "bd",
                                                "be",
                                                "bf",
                                                "bg",
                                                "bh",
                                                "bi",
                                                "bj",
                                                "bl",
                                                "bm",
                                                "bn",
                                                "bo",
                                                "bq",
                                                "br",
                                                "bs",
                                                "bt",
                                                "bv",
                                                "bw",
                                                "by",
                                                "bz",
                                                "ca",
                                                "cc",
                                                "cd",
                                                "cf",
                                                "cg",
                                                "ch",
                                                "ci",
                                                "ck",
                                                "cl",
                                                "cm",
                                                "cn",
                                                "co",
                                                "cr",
                                                "cu",
                                                "cv",
                                                "cw",
                                                "cx",
                                                "cy",
                                                "cz",
                                                "de",
                                                "dj",
                                                "dk",
                                                "dm",
                                                "do",
                                                "dz",
                                                "ec",
                                                "ee",
                                                "eg",
                                                "eh",
                                                "er",
                                                "es",
                                                "et",
                                                "fi",
                                                "fj",
                                                "fk",
                                                "fm",
                                                "fo",
                                                "fr",
                                                "ga",
                                                "gb",
                                                "gd",
                                                "ge",
                                                "gf",
                                                "gg",
                                                "gh",
                                                "gi",
                                                "gl",
                                                "gm",
                                                "gn",
                                                "gp",
                                                "gq",
                                                "gr",
                                                "gs",
                                                "gt",
                                                "gu",
                                                "gw",
                                                "gy",
                                                "hk",
                                                "hm",
                                                "hn",
                                                "hr",
                                                "ht",
                                                "hu",
                                                "id",
                                                "ie",
                                                "il",
                                                "im",
                                                "in",
                                                "io",
                                                "iq",
                                                "ir",
                                                "is",
                                                "it",
                                                "je",
                                                "jm",
                                                "jo",
                                                "jp",
                                                "ke",
                                                "kg",
                                                "kh",
                                                "ki",
                                                "km",
                                                "kn",
                                                "kp",
                                                "kr",
                                                "kw",
                                                "ky",
                                                "kz",
                                                "la",
                                                "lb",
                                                "lc",
                                                "li",
                                                "lk",
                                                "lr",
                                                "ls",
                                                "lt",
                                                "lu",
                                                "lv",
                                                "ly",
                                                "ma",
                                                "mc",
                                                "md",
                                                "me",
                                                "mf",
                                                "mg",
                                                "mh",
                                                "mk",
                                                "ml",
                                                "mm",
                                                "mn",
                                                "mo",
                                                "mp",
                                                "mq",
                                                "mr",
                                                "ms",
                                                "mt",
                                                "mu",
                                                "mv",
                                                "mw",
                                                "mx",
                                                "my",
                                                "mz",
                                                "na",
                                                "nc",
                                                "ne",
                                                "nf",
                                                "ng",
                                                "ni",
                                                "nl",
                                                "no",
                                                "np",
                                                "nr",
                                                "nu",
                                                "nz",
                                                "om",
                                                "pa",
                                                "pe",
                                                "pf",
                                                "pg",
                                                "ph",
                                                "pk",
                                                "pl",
                                                "pm",
                                                "pn",
                                                "pr",
                                                "ps",
                                                "pt",
                                                "pw",
                                                "py",
                                                "qa",
                                                "re",
                                                "ro",
                                                "rs",
                                                "ru",
                                                "rw",
                                                "sa",
                                                "sb",
                                                "sc",
                                                "sd",
                                                "se",
                                                "sg",
                                                "sh",
                                                "si",
                                                "sj",
                                                "sk",
                                                "sl",
                                                "sm",
                                                "sn",
                                                "so",
                                                "sr",
                                                "ss",
                                                "st",
                                                "sv",
                                                "sx",
                                                "sy",
                                                "sz",
                                                "tc",
                                                "td",
                                                "tf",
                                                "tg",
                                                "th",
                                                "tj",
                                                "tk",
                                                "tl",
                                                "tm",
                                                "tn",
                                                "to",
                                                "tr",
                                                "tt",
                                                "tv",
                                                "tw",
                                                "tz",
                                                "ua",
                                                "ug",
                                                "um",
                                                "us",
                                                "uy",
                                                "uz",
                                                "va",
                                                "vc",
                                                "ve",
                                                "vg",
                                                "vi",
                                                "vn",
                                                "vu",
                                                "wf",
                                                "ws",
                                                "ye",
                                                "yt",
                                                "za",
                                                "zm",
                                                "zw",
                                            ],
                                        },
                                        // creatives: item.creatives,
                                    });
                                    setCreatives(
                                        item.creatives.map((item) => {
                                            return {
                                                ...item,
                                                // editId: item.id,
                                                readOnly: true,
                                            };
                                        })
                                    );
                                    setCampaignWizardStatus("edit");
                                    setShowCampaignWizardDrawer("block");
                                    setCampaignTabs({
                                        ...campaignTabs,
                                        // marked: [...campaignTabs.marked, 1, 2, 3],
                                        // disabled: [...campaignTabs.disabled, 2],
                                        // clickable: [...campaignTabs.clickable, 1, 3],

                                        marked: [...campaignTabs.marked, 1, 2, 3],
                                        // disabled: [...campaignTabs.disabled, 2],
                                        clickable: [...campaignTabs.clickable, 1, 2, 3],
                                    });
                                }}
                                disabled={campaignBtns.editBtn.isReadOnly}
                            />
                            {campaignBtns.editBtn.isReadOnly && (
                                <Tooltip id="edit-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                        </div>
                        <div
                            className={`delete-btn-wrapper ${isDeleteLoading ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="delete-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.deleteBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.deleteBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Delete"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={trashV2}
                                // disabled={isDeleteLoading}
                                // onClick={() => handleDeleteCampaign(item.id)}
                                onClick={() => {
                                    setDisplayDeleteModal("block");
                                }}
                                disabled={campaignBtns.deleteBtn.isReadOnly}
                            />
                            {campaignBtns.deleteBtn.isReadOnly && (
                                <Tooltip id="delete-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                            <Modal
                                Toggler={() => {}}
                                Child={DeleteCampaignModal}
                                // modalCloseCallback={modalCloseCallback}
                                display={displayDeleteModal}
                                setDisplay={setDisplayDeleteModal}
                                className="modal-dialog-centered modal-sm"
                            />
                        </div>
                    </div>
                </td>
                {/* <td className="table-td-status text-center">
                    <div
                        data-tooltip-id="status-tooltip"
                        data-tooltip-html={
                            campaignDetails.status === "Paused · Daily Budget"
                                ? ReactDOMServer.renderToStaticMarkup(
                                       <span>Daily budget limit reached.</span>
                                  )
                                : ""
                        }
                        className={`status ${
                            campaignDetails.status === "Paused · Daily Budget"
                                ? "paused-daily-budget"
                                : campaignDetails.status
                        }`}
                    >
                        {campaignDetails.status}
                    </div>
                    <Tooltip id="status-tooltip" className="custom-tooltip" />
                </td> */}
                <td className="table-td-impressions text-center">{item.stats.Total_impressions}</td>
                <td className="table-td-impressions text-center">{0}</td>
                <td className="table-td-clicks text-center">{item.stats.Total_clicks}</td>
                <td className="table-td-ctr text-center">{0}</td>
                <td className="table-td-ad-spend text-center">{item.stats.Total_earnings}</td>
                <td className="table-td-conversion text-center">
                    {/* <span>
                        {advertiserPixelStats.isLoading
                            ? "Loading..."
                            : advertiserPixelStats.value.length
                            ? advertiserPixelStats.value.find(statItem=>statItem.bca_ID == item.id)?.conversionCount || 0
                            : 0}
                    </span> */}
                    {0}
                </td>
                <td className="table-td-txn-value text-center">
                    {0}
                </td>
            </tr>
            {activeInfoId === item.id && (
                <tr className="campaign-details-tr">
                    <td colSpan={11} className="campaign-info-td">
                        <CampaignInfo data={item} isLoading={false} />
                    </td>
                </tr>
            )}
        </>
    );
};
