import React from "react";

export default function useUtil() {
    const getFileFromCanvas = async (canvas) => {
        return new Promise((resolve, reject) => {
            canvas.toBlob(function (blob) {
                if (blob) {
                    var file = new File([blob], "image.jpg", {
                        type: "image/jpeg",
                        lastModified: Date.now(), // Set last modified time to current time
                    });
                    resolve(file);
                } else {
                    reject(new Error("Failed to generate blob from canvas."));
                }
            }, "image/jpeg");
        });
    };

    async function resizeImage(file, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
            // Check if the uploaded file is a GIF
            // if (file.type === "image/gif") {
            //     // Resolve with the original image without resizing
            //     resolve(file);
            //     return;
            // }

            // Proceed with resizing for non-GIF images
            const img = new Image();

            img.onload = () => {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth || height > maxHeight) {
                    const aspectRatio = width / height;

                    if (aspectRatio > 1) {
                        width = maxWidth;
                        height = width / aspectRatio;
                    } else {
                        height = maxHeight;
                        width = height * aspectRatio;
                    }
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type);
            };

            img.onerror = (error) => {
                reject(error);
            };

            const reader = new FileReader();
            reader.onload = (event) => {
                img.src = event.target.result;
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

    function resizeFile(file, width, height) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var img = new Image();
                img.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;

                    var ctx = canvas.getContext("2d");
                    var scale = Math.max(width / img.width, height / img.height);
                    var x = width / 2 - (img.width / 2) * scale;
                    var y = height / 2 - (img.height / 2) * scale;
                    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

                    canvas.toBlob(function (blob) {
                        var resizedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });
                        resolve(resizedFile);
                    }, file.type);
                };
                img.onerror = reject; // Handle image loading errors
                img.src = event.target.result;
            };
            reader.onerror = reject; // Handle file reading errors
            reader.readAsDataURL(file);
        });
    }

    async function copyText(textToCopy){
        const text = textToCopy.innerText || textToCopy.value
        try {
              await navigator.clipboard.writeText(text)
            // console.log('Text copied successfully');
            return text
        } catch(err){
            // console.error('Could not copy text: ', err);
        }
        
    }
    async function copyText2(text){
      
        try {
              await navigator.clipboard.writeText(text)
            // console.log('Text copied successfully');
            return text
        } catch(err){
            // console.error('Could not copy text: ', err);
        }
        
    }

    const checkForBalanceOnLast7days = (allOrganization)=>{
        // allOrganization.forEach(item=>{
        //     console.log(item.totalPayments || 'unknown')
        // })
   }

    return { getFileFromCanvas, resizeImage, resizeFile,copyText, copyText2,checkForBalanceOnLast7days };
}
