import React from "react";
import localData from "../../../localData";
import { Button } from "../../";

export default function ContactCard({ title = "", icon = null, link = "", linkName='' }) {
    const { arrowUpRight } = localData.svgs;
    return (
        <a className="card contact-card" href={link} target="_blank">
            <Button color="primary">
                <div className="card-icon">{icon}</div>
                <div className="card-link-icon">{arrowUpRight}</div>
                    <p className="card-pathname">{linkName}</p>
                    <p className="card-title">{title}</p>
            </Button>
        </a>
    );
}
