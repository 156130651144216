// import React, { PureComponent, useEffect } from "react";
// import {
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     ResponsiveContainer,
//     Scatter,
//     ReferenceLine,
// } from "recharts";
// import { Loading } from "../../../";
// import CustomTooltip from "../custom-tooltip/CustomTooltip";

// const isSmallScreen = window.innerWidth < 768;
// const fontSize = isSmallScreen ? 8 : 12;

// export default class Example extends PureComponent {
//     static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

//     render() {
//         const {
//             data = [],
//             defaultData = [],
//             isLoading = false,
//             uvColor = "",
//             amtColor = "",
//             lines = [],
//         } = this.props;
//         console.log(data)
//         return (
//             <div className="position">
//                 <div className="position-inner">
//                     <ResponsiveContainer width="100%" height="100%">
//                         <LineChart
//                             width={500}
//                             height={300}
//                             data={data.length && data.length > 1 && !isLoading ? data : defaultData}
//                             // data={data.length && !isLoading ? data : defaultData}
//                             // data={defaultData}
//                             margin={{
//                                 top: 30,
//                                 right: 0,
//                                 left: 0,
//                                 bottom: 30,
//                             }}
//                         >
//                             <CartesianGrid strokeDasharray="" vertical={false} />
//                             <XAxis
//                                 dataKey="name"
//                                 axisLine={false}
//                                 tickLine={false}
//                                 dy={15}
//                                 tick={{ fontSize: fontSize }}
//                             />
//                             {/* <YAxis
//                                 axisLine={false}
//                                 tick={{ fontSize: fontSize }}
//                                 tickLine={false}
//                                 dx={-5}
//                                 // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
//                             /> */}
//                             <Tooltip content={<CustomTooltip active={true} />}/>

//                             {!lines.length
//                                 ? ""
//                                 : lines.map((item, index) => {
//                                       return (
//                                           <Line
//                                               key={index}
//                                               type="linear"
//                                               strokeWidth="2"
//                                               dataKey={item.dataName}
//                                               stroke={item.color}
//                                               dot={false}
//                                           />
//                                       );
//                                   })}
//                             <Line type="linear" strokeWidth="3" dataKey="empty" stroke={"#808080"} dot={false} />
//                             {/* <ReferenceLine y={500} stroke="#ccc" />
//                             <ReferenceLine y={1000} stroke="#ccc" /> */}
//                         </LineChart>
//                     </ResponsiveContainer>
//                 </div>
//             </div>
//         );
//     }
// }

import React, { PureComponent } from "react";
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({ data = [], defaultData = [], isLoading = false, lines = [] }) {
    // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
    const { formatNumber, formatCurrency } = useFormatter();
    return (
        <div className="position">
            <div className="position-inner">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.length && !isLoading ? data : defaultData}
                        margin={{
                            top: 30,
                            right: 0,
                            left: leftMargin,
                            bottom: 30,
                        }}
                    >
                        <CartesianGrid strokeDasharray="" vertical={false} />
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            dy={15}
                            tick={{ fontSize: fontSize }}
                            // interval="auto"
                        />
                        <YAxis
                            axisLine={false}
                            tick={{ fontSize: fontSize }}
                            tickLine={false}
                            dx={-5}
                            // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
                        />
                        <Tooltip
                            formatter={(value, name, props) => {
                                if (name === "impressions") return formatNumber(value);
                                if (name === "ad spend") return formatCurrency(value, 2);
                                if (name === "clicks") return formatNumber(value);
                                if (name === "CPC") return formatCurrency(value, 2);
                                if (name === "CPM") return formatCurrency(value, 2);

                                // if (name === "Conversions") return formatNumber(value);
                                // if (name === "Engaged Sessions") return formatNumber(value);
                                // if (name === "Engagement Rate") return formatNumber(value);
                                // if (name === "Event Count") return formatNumber(value);
                                // if (name === "New Users") return formatNumber(value);
                                // if (name === "Total Revenue") return formatNumber(value);

                                
                                if (name === "unknown") return "0";
                                return formatNumber(value);
                            }}
                        />

                        {!lines.length
                            ? ""
                            : lines.map((line, index) => {
                                  return (
                                      <Line
                                          key={index}
                                          dataKey={line.propertyName}
                                          name={line.displayName}
                                          stackId={data.length > 10 ? "a" : undefined}
                                          type="linear"
                                          strokeWidth="3"
                                          //   fill={line.color}
                                          stroke={line.color}
                                          dot={false}
                                      />
                                  );
                              })}
                        <Line
                            type="linear"
                            dataKey="empty"
                            name="unknown"
                            stackId="a"
                            strokeWidth="1"
                            stroke={"#808080"}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
