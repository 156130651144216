
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials,
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";

import { auth, googleProvider } from "../../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

export default function SignIn() {
    const { fade } = useGlobalContext().animations;
    const {
        isLoggedIn,
        setIsLoggedIn,
        errorAlert,
        successAlert,
        handleGoogleSignIn,
        handleFacebookSignIn,
        getLoadUserProfile,
        initRequests
    } = useGlobalContext();
    const { disconnect } = localData.svgs;
    const { preloaderBig } = localData.images;
    const { logoDark, google, facebook } = localData.images;
    const [isLoading, setIsLoading] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const navigate = useNavigate();

    // console.log(auth?.currentUser?.email, 'sign in page');

    const [raw, setRaw] = useState({
        username: "",
        password: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setRaw({ ...raw, [name]: value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Replace with your API call to check authentication
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/login.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                   // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Adjust according to your API
                },
                body: JSON.stringify({
                    username: raw.email,
                    password: raw.password,
                    "client_id":"blockchain-ads",
                    "client_secret":"blockchain-adsapi",
                    "grant_type":"password"
                }),
            }); 
            const data = await response.json();           
            if (!data.error && data.account_type == 'TRAFFICKER') {                
                successAlert("You have successfully signed in!");
                setIsLoggedIn(true); 
                localStorage.setItem("user",JSON.stringify(data));
                navigate("/");
                //setAuthUser(data);
                initRequests();
            } else {
                if(data.error_description){
                    errorAlert(data.error_description);
                    setIsLoggedIn(false);
                } else {
                    errorAlert("Invalid username and password combination");
                    setIsLoggedIn(false);
                }
                
            } 
        } catch (err) {
            console.error(err);
            setIsLoggedIn(false);
        }
        setIsLoading(false);
    };

    return (
        <motion.main {...fade} className="main sign-in-page">
            <div className="container">
                <div className="col col-left">
                    <div className="wrapper">
                        <form className="form" onSubmit={handleLogin}>
                            <img className="logo" src={logoDark} />

                            <div className="form-description">Sign in or create an account</div>

                            <h2 className="form-title">Sign In</h2>

                            <Field
                                type="email"
                                label="Email"
                                color="secondary"
                                placeholder="Enter your email address"
                                required={true}
                                name="email"
                                callback={handleOnChange}
                            />

                            <Field 
                                type="password"
                                label="Password"
                                link={ 
                                    <Link
                                        to="/forgot-password"
                                        underline="hover"
                                        className="link link-primary"
                                        tabIndex={-1}
                                    >
                                        {" "}
                                        Forgot Password
                                    </Link>
                                }
                                color="secondary"
                                placeholder="Enter your password"
                                required={true}
                                name="password"
                                callback={handleOnChange}
                            />
                             
                            <Button
                                className="submit-btn"
                                name="Sign In"
                                color="primary"
                                variant="contained"
                                size="lg"
                                icon={
                                    isLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isLoading}
                            />
                        </form>

                        <div className="cut-line">
                            <div className="cut-line-inner">or</div>
                        </div>

                        {/* <Button
                            variant="outlined"
                            size="lg"
                            color="secondary"
                            className="alt social-btn google-btn"
                            onClick={handleGoogleSignIn}
                        >
                            <span className="startIcon">
                                <img src={google} alt="" />
                            </span>
                            Sign in with Google
                        </Button> */}

                        {/* <Button
                            variant="outlined"
                            size="lg"
                            color="secondary"
                            className="alt social-btn facebook-btn"
                            onClick={handleFacebookSignIn}
                        >
                            <span className="startIcon">
                                <img src={facebook} alt="" />
                            </span>
                            Sign in with Facebook
                        </Button> */}
                        <p className="login-signup-now">
                            Don’t have an account?
                            <Link to="/sign-up" underline="hover" className="link link-primary">
                                {" "}
                                Sign up
                            </Link>
                        </p>
                    </div>
                    <p className="copyright">
                        ©Blockchain-Ads. All rights reserved.
                        <Link to="/privacy-policy" underline="hover" className="link link-primary">
                            {" "}
                            Privacy Policy
                        </Link>
                    </p>
                </div>

                <div className="col col-right">
                    <Testimonials />
                </div>
            </div>
        </motion.main>
    );
}
