import React, { useState, useEffect } from "react";
import useUtil from "../../../../../hooks/useUtil";
import { useGlobalContext } from "../../../../../context";
import { CopyTextButton, Button, Modal, Tabs } from "../../../../../components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import localData from "../../../../../localData";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import TrackingHelp from "../../../tracking-help/TrackingHelp";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function InstallPixelTab({ setActiveTab }) {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
        },
    } = useGlobalContext();
    const [script, setScript] = useState("");
    useEffect(() => {
        CreatePixelTagRequest();
    }, []);

    const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");

    return (
        <div className="install-pixel-tab">
            <h4 className="install-pixel-tab-title ">Install Pixel</h4>

            <p className="install-pixel-tab-description text-style-1">
                Install the Blockchain-Ads pixel in the {`<head>`} tag on all pages of the website. If you use
                Google Tag Manager, create a custom HTML tag and install on a Pageviews trigger that fires on
                all pages.
            </p>
            <br />

            <Tabs
                // hideTabs={true}
                tabsProps={{}}
                tabs={installPixelCodeTabs}
                title="fsdfsd"
                // indicatorIcon={question}
                // className="install-pixel-code-tabs"
            />
            <br />

            <div className="install-pixel-tab-footer">
                <div className="btn-group">
                    <Modal
                        Child={InstallPixelModal}
                        className="install-pixel-modal tracking-modal modal-dialog-centered"
                        display={showInstallPixelModal}
                        setDisplay={setShowInstallPixelModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                className="next-step-btn"
                                name="Next"
                                endIcon={arrowRight}
                                size="sm"
                                variant="contained"
                                color="pale-dark"
                                onClick={() => setShowInstallPixelModal("block")}
                            />
                        )}
                    ></Modal>
                </div>
            </div>
        </div>
    );
}

const InstallPixelModal = ({ setIsOpen, setActiveTab }) => {
    const { conversionOptions } = useGlobalContext();
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                {/* need to confirm
                <br />
                <br /> */}
                <TrackingHelp />
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        className="next-step-btn"
                        name="Continue"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={(e) => {
                            closeModal();
                            setTimeout(() => {
                                setActiveTab(1);
                            }, 500);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const GoogleTagManagerContent = () => {
    const { conversionOptions } = useGlobalContext();
    const gmtLink = `<script>
       var script = document.createElement('script');
       script.defer = true;
       script.src = 'https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js';
       script.dataset.siteId = "1264";
       document.getElementsByTagName('head')[0].appendChild(script);
    </script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={gtm} alt="" />
                    Google Tag Manager (GTM)
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>

            <TrackingHelp />
        </div>
    );
};

const NextJSContent = () => {
    const { conversionOptions } = useGlobalContext();
    const nextjsLink = `<Script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    />`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={nextjs} alt="" />
                    _app.txt
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const HTMLContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
        src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
        integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
        crossOrigin="anonymous"
        async="true"
        strategy="lazyOnload"
        data-site-id="1264"
        data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={html} alt="" />
                    index.html
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const NuxtContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={nuxt} alt="" />
                    app.vue
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const WordpressContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={wordpress} alt="" />
                    functions.php
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const PixelContent = () => {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
        },
    } = useGlobalContext();

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={pixel} alt="" />
                    pixel
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
            <TrackingHelp />
        </div>
    );
};

const installPixelCodeTabs = [
    // {
    //     title: "Google Tag Manager",
    //     startImageIcon: gtm,
    //     content: <GoogleTagManagerContent />,
    //     id: "tracking-1",
    // },
    // {
    //     title: "Next.js",
    //     startImageIcon: nextjs,
    //     content: <NextJSContent />,
    //     id: "tracking-2",
    // },
    // {
    //     title: "HTML",
    //     startImageIcon: html,
    //     content: <HTMLContent />,
    //     id: "tracking-3",
    // },
    // {
    //     title: "Nuxt",
    //     startImageIcon: nuxt,
    //     content: <NuxtContent />,
    //     id: "tracking-4",
    // },
    // {
    //     title: "Wordpress",
    //     startImageIcon: wordpress,
    //     content: <WordpressContent />,
    //     id: "tracking-5",
    // },
    {
        title: "Pixel",
        startImageIcon: pixel,
        content: <PixelContent />,
        id: "tracking-6",
    },
];
