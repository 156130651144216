import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "./context";

export const StateContext = createContext();

// const endDate = new Date(); // today
// const startDate = new Date(endDate);
// startDate.setDate(endDate.getDate() - 6); // 7 days ago

// const SELECTIONRANGE = {
//     startDate,
//     endDate,
//     key: "selection",
// };

export default function StateProvider({ children }) {
    // const { setRequestedData, activeCampaign, getLoadBlockchainData } = useGlobalContext();

    // BLOCKCHAINS

    // const [blockchains, setBlockchains] = useState([
    //     // {
    //     //     blockNumber: "34984225",
    //     //     timeStamp: "1704474596",
    //     //     hash: "0xc6311249d28cd266a8dea4c61c357f1c0637237e5851b37205a4ddf1299b6dfb",
    //     //     nonce: "2817",
    //     //     blockHash: "0xca3cc0eec96846b20ca2dd0ca51bb4254e899777ced18fefec7da623d88be1e6",
    //     //     transactionIndex: "72",
    //     //     from: "0xe9bd8e3d539d46fab8a5cc61baefd9ff2d511908",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000b7933eb7a36ce8387910519f9eba49403871c4c00000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "7392839",
    //     //     gasUsed: "24124",
    //     //     confirmations: "710280",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "34634358",
    //     //     timeStamp: "1703422815",
    //     //     hash: "0xfcc1963467c3cf2a1f0fad7413687e96b0a374abfc654755dba2eda08c97b79d",
    //     //     nonce: "1986",
    //     //     blockHash: "0x2a0b0b6911fc88a18cbbbe8ad514992c3a83dc5ae351140b85751ef0b7c2a9e6",
    //     //     transactionIndex: "261",
    //     //     from: "0xfb90362431f9737a2e1ebde350be217181538c13",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "21825547",
    //     //     gasUsed: "24124",
    //     //     confirmations: "1060147",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "33629692",
    //     //     timeStamp: "1700398762",
    //     //     hash: "0xc68af00f3d0a66a48764c49ceba8cea7480187563d92868d6f385d22fdeb3340",
    //     //     nonce: "938",
    //     //     blockHash: "0x3f53c1a0fb3a939dc52f975f16f826167cd46dbbdc813b7a898d72b2c13ee023",
    //     //     transactionIndex: "3",
    //     //     from: "0x13a74008821a0b2d3df4d0663d44f451aa96c36c",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46132",
    //     //     gasPrice: "5000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd800000000000000000000000000000000000000000000000bf736b55c4c6b8347",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "309585",
    //     //     gasUsed: "46132",
    //     //     confirmations: "2064813",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "33314878",
    //     //     timeStamp: "1699450486",
    //     //     hash: "0xa909ed0b331eb471c65478a53bf575ecd99046532900c89fbc19f722c0d5d18f",
    //     //     nonce: "158",
    //     //     blockHash: "0x736c83534f88844641a1bcc02e58de07bb403c368b244ccae6125d34e95ec7ec",
    //     //     transactionIndex: "96",
    //     //     from: "0xf83b7f915f2fa0e518fda1c0be10633ef564b3e8",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "51048",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd8ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "11927199",
    //     //     gasUsed: "46408",
    //     //     confirmations: "2379627",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32732059",
    //     //     timeStamp: "1697695667",
    //     //     hash: "0x823c7b1021c7819e67dcfd5cbccedaaf1493001afe164b50b8c167c14b9472ae",
    //     //     nonce: "1374",
    //     //     blockHash: "0x928cfcbfa374a95da38afa9b7757ea54662ea919bfa717a240c80256d55e73d7",
    //     //     transactionIndex: "91",
    //     //     from: "0xa41868b9989ace59e238a8cd5471d7f0e1dd83b2",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46408",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b061739ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "12850437",
    //     //     gasUsed: "46408",
    //     //     confirmations: "2962446",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32395380",
    //     //     timeStamp: "1696682212",
    //     //     hash: "0x6c7e7e007545f9f05adeb17b3369689386970908c62f6676d1316fcdb788b6e1",
    //     //     nonce: "383",
    //     //     blockHash: "0x7a74c22e0ff9aa43b5870b25817c0c9d129ebedfc838bfdb626f1defe382e67c",
    //     //     transactionIndex: "59",
    //     //     from: "0xa11113c81523e9fe30967a5948803d5c1b8d9b1c",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46408",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b061739ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "5012025",
    //     //     gasUsed: "46408",
    //     //     confirmations: "3299125",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32238739",
    //     //     timeStamp: "1696210354",
    //     //     hash: "0x54515d6c38f1c6fcd4cac9e54b0e81451de7a889c8761f21eb173f03f5388534",
    //     //     nonce: "10379",
    //     //     blockHash: "0x17dd947bb122e941c4fc6d34736ad3201961d77b7a78ab8dc9914afea5f4c391",
    //     //     transactionIndex: "82",
    //     //     from: "0x0d971b7b7520f1fce9b90665ca59952ea2c52b04",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "69612",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd8ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "7271933",
    //     //     gasUsed: "46408",
    //     //     confirmations: "3455766",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32055797",
    //     //     timeStamp: "1695659856",
    //     //     hash: "0x50e936eec54f66badaee99c0c4a72a6307f36396329b3d6b1e70068cc08e51fc",
    //     //     nonce: "2715",
    //     //     blockHash: "0xc9b1c7a5f898e29d54a30611cf23df642faeff3a01dd0135beff1f25d27cbb84",
    //     //     transactionIndex: "28",
    //     //     from: "0xe9bd8e3d539d46fab8a5cc61baefd9ff2d511908",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "2283894",
    //     //     gasUsed: "24124",
    //     //     confirmations: "3638708",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32023477",
    //     //     timeStamp: "1695562639",
    //     //     hash: "0x8ec7edf27bc5083c49459e6c42b02c47194b990edb79a4d0c6bffd8fb7498307",
    //     //     nonce: "484",
    //     //     blockHash: "0x9dbd4514805510bd158515da6ce50a5150907ed01e67d50a6f9c146143a1193d",
    //     //     transactionIndex: "25",
    //     //     from: "0x9294a3639f39c7d95dee36c611d94cebabdf6bb9",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "26124",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "2368673",
    //     //     gasUsed: "26124",
    //     //     confirmations: "3671028",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32023413",
    //     //     timeStamp: "1695562446",
    //     //     hash: "0xa1d456d2ccde31c4e66df85c7ed91102e49981c89c583450a03c5a54546eba96",
    //     //     nonce: "481",
    //     //     blockHash: "0x050506968dd4cbcc3736d6b0f2c1334de9d3dd6145c6fa8606e3315b9b7884bf",
    //     //     transactionIndex: "97",
    //     //     from: "0x9294a3639f39c7d95dee36c611d94cebabdf6bb9",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "26124",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "8937318",
    //     //     gasUsed: "26124",
    //     //     confirmations: "3671092",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    // ]);
    // const [blockchainsPage, setBlockchainsPage] = useState(1);
    // const [blockchainsPageSize, setBlockchainsPageSize] = useState(10);
    // const [blockchainsTotalPages, setBlockchainsTotalPages] = useState(10);

    // const [blockchainsItemsPerPage, setBlockchainsItemsPerPage] = useState([
    //     { title: 5, isSelected: false, id: uuidv4() },
    //     { title: 10, isSelected: true, id: uuidv4() },
    //     { title: 15, isSelected: false, id: uuidv4() },
    // ]);
    // useEffect(() => {
    //     const blockchainsStates = JSON.parse(localStorage.getItem("blockchainsStates"));
    //     if (blockchainsStates?.pageSize) {
    //         let tempItemsPerPage = [...blockchainsItemsPerPage];
    //         tempItemsPerPage = tempItemsPerPage.map((item) => ({
    //             title: item.title,
    //             isSelected: item.title === blockchainsStates.pageSize,
    //             id: item.id,
    //         }));
    //         setBlockchainsItemsPerPage(tempItemsPerPage);
    //         setBlockchainsPageSize(blockchainsStates.pageSize);
    //     }
    // }, []);

    // const resetBlockchainData = () => {
    //     setRequestedData((prev) => ({
    //         ...prev,
    //         blockchainData: [],
    //     }));
    // };

    // useEffect(() => {
    //     setBlockchainsPage(1);
    //     if (!activeCampaign) return resetBlockchainData();
    //     const smartContractAddress = activeCampaign.extraData.smartContractAddress;
    //     if (!smartContractAddress || smartContractAddress === "N/A") return resetBlockchainData();
    //     if (Array.isArray(activeCampaign.extraData.chainTargeting)) return resetBlockchainData(); //DELETE AFTER SOME TIME (AFTER OLD CAMPAIGNS REMOVED)
    //     const chainTargeting =
    //         activeCampaign.extraData.chainTargeting.toLowerCase() === "binance smart chain" ? "BSC" : "ETH";
    //     getLoadBlockchainData(activeCampaign.extraData.smartContractAddress, chainTargeting);
    // }, [activeCampaign]);

    // // CAMPAIGNS
    // const [campaignsPage, setCampaignsPage] = useState(1);
    // const [campaignsPageSize, setCampaignsPageSize] = useState(10);
    // const [campaignsTotalPages, setCampaignsTotalPages] = useState(10);

    // const [campaignsItemsPerPage, setCampaignsItemsPerPage] = useState([
    //     { title: 5, isSelected: false, id: uuidv4() },
    //     { title: 10, isSelected: true, id: uuidv4() },
    //     { title: 15, isSelected: false, id: uuidv4() },
    // ]);

    // useEffect(() => {
    //     const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
    //     if (campaignsStates?.pageSize) {
    //         let tempItemsPerPage = [...campaignsItemsPerPage];
    //         tempItemsPerPage = tempItemsPerPage.map((item) => ({
    //             title: item.title,
    //             isSelected: item.title === campaignsStates.pageSize,
    //             id: item.id,
    //         }));
    //         setCampaignsItemsPerPage(tempItemsPerPage);
    //         setCampaignsPageSize(campaignsStates.pageSize);
    //     }
    // }, []);

    // const [campaignSelectionRange, setCampaignSelectionRange] = useState(SELECTIONRANGE);

    // useEffect(() => {
    //     const selectionRange = JSON.parse(localStorage.getItem("selectionRange"));

    //     if (selectionRange) {
    //         const { startDate, endDate } = selectionRange;
    //         setCampaignSelectionRange({
    //             startDate: new Date(startDate),
    //             endDate: new Date(endDate),
    //             key: "selection",
    //         });
    //     }
    // }, []);

    // //  CAMPAIGNS WIZARD
    // const [campaignTabs, setCampaignTabs] = useState({
    //     clickable: [],
    //     marked: [],
    //     disabled: [],
    // });

    // const [displayCreativesModal, setDisplayCreativesModal] = useState("none");
    // const [creativesState, setCreativesState] = useState({
    //     uploadedImage: null,
    //     croppedImage: null,
    //     file: null,
    // });

    // REPORTS
    // const [reports, setReports] = useState([
    //     // {
    //     //     blockNumber: "34984225",
    //     //     timeStamp: "1704474596",
    //     //     hash: "0xc6311249d28cd266a8dea4c61c357f1c0637237e5851b37205a4ddf1299b6dfb",
    //     //     nonce: "2817",
    //     //     blockHash: "0xca3cc0eec96846b20ca2dd0ca51bb4254e899777ced18fefec7da623d88be1e6",
    //     //     transactionIndex: "72",
    //     //     from: "0xe9bd8e3d539d46fab8a5cc61baefd9ff2d511908",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000b7933eb7a36ce8387910519f9eba49403871c4c00000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "7392839",
    //     //     gasUsed: "24124",
    //     //     confirmations: "710280",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "34634358",
    //     //     timeStamp: "1703422815",
    //     //     hash: "0xfcc1963467c3cf2a1f0fad7413687e96b0a374abfc654755dba2eda08c97b79d",
    //     //     nonce: "1986",
    //     //     blockHash: "0x2a0b0b6911fc88a18cbbbe8ad514992c3a83dc5ae351140b85751ef0b7c2a9e6",
    //     //     transactionIndex: "261",
    //     //     from: "0xfb90362431f9737a2e1ebde350be217181538c13",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "21825547",
    //     //     gasUsed: "24124",
    //     //     confirmations: "1060147",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "33629692",
    //     //     timeStamp: "1700398762",
    //     //     hash: "0xc68af00f3d0a66a48764c49ceba8cea7480187563d92868d6f385d22fdeb3340",
    //     //     nonce: "938",
    //     //     blockHash: "0x3f53c1a0fb3a939dc52f975f16f826167cd46dbbdc813b7a898d72b2c13ee023",
    //     //     transactionIndex: "3",
    //     //     from: "0x13a74008821a0b2d3df4d0663d44f451aa96c36c",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46132",
    //     //     gasPrice: "5000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd800000000000000000000000000000000000000000000000bf736b55c4c6b8347",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "309585",
    //     //     gasUsed: "46132",
    //     //     confirmations: "2064813",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "33314878",
    //     //     timeStamp: "1699450486",
    //     //     hash: "0xa909ed0b331eb471c65478a53bf575ecd99046532900c89fbc19f722c0d5d18f",
    //     //     nonce: "158",
    //     //     blockHash: "0x736c83534f88844641a1bcc02e58de07bb403c368b244ccae6125d34e95ec7ec",
    //     //     transactionIndex: "96",
    //     //     from: "0xf83b7f915f2fa0e518fda1c0be10633ef564b3e8",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "51048",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd8ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "11927199",
    //     //     gasUsed: "46408",
    //     //     confirmations: "2379627",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32732059",
    //     //     timeStamp: "1697695667",
    //     //     hash: "0x823c7b1021c7819e67dcfd5cbccedaaf1493001afe164b50b8c167c14b9472ae",
    //     //     nonce: "1374",
    //     //     blockHash: "0x928cfcbfa374a95da38afa9b7757ea54662ea919bfa717a240c80256d55e73d7",
    //     //     transactionIndex: "91",
    //     //     from: "0xa41868b9989ace59e238a8cd5471d7f0e1dd83b2",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46408",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b061739ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "12850437",
    //     //     gasUsed: "46408",
    //     //     confirmations: "2962446",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32395380",
    //     //     timeStamp: "1696682212",
    //     //     hash: "0x6c7e7e007545f9f05adeb17b3369689386970908c62f6676d1316fcdb788b6e1",
    //     //     nonce: "383",
    //     //     blockHash: "0x7a74c22e0ff9aa43b5870b25817c0c9d129ebedfc838bfdb626f1defe382e67c",
    //     //     transactionIndex: "59",
    //     //     from: "0xa11113c81523e9fe30967a5948803d5c1b8d9b1c",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "46408",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b061739ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "5012025",
    //     //     gasUsed: "46408",
    //     //     confirmations: "3299125",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32238739",
    //     //     timeStamp: "1696210354",
    //     //     hash: "0x54515d6c38f1c6fcd4cac9e54b0e81451de7a889c8761f21eb173f03f5388534",
    //     //     nonce: "10379",
    //     //     blockHash: "0x17dd947bb122e941c4fc6d34736ad3201961d77b7a78ab8dc9914afea5f4c391",
    //     //     transactionIndex: "82",
    //     //     from: "0x0d971b7b7520f1fce9b90665ca59952ea2c52b04",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "69612",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b30000000000000000000000003a6d8ca21d1cf76f653a67577fa0d27453350dd8ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "7271933",
    //     //     gasUsed: "46408",
    //     //     confirmations: "3455766",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32055797",
    //     //     timeStamp: "1695659856",
    //     //     hash: "0x50e936eec54f66badaee99c0c4a72a6307f36396329b3d6b1e70068cc08e51fc",
    //     //     nonce: "2715",
    //     //     blockHash: "0xc9b1c7a5f898e29d54a30611cf23df642faeff3a01dd0135beff1f25d27cbb84",
    //     //     transactionIndex: "28",
    //     //     from: "0xe9bd8e3d539d46fab8a5cc61baefd9ff2d511908",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "28924",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "2283894",
    //     //     gasUsed: "24124",
    //     //     confirmations: "3638708",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32023477",
    //     //     timeStamp: "1695562639",
    //     //     hash: "0x8ec7edf27bc5083c49459e6c42b02c47194b990edb79a4d0c6bffd8fb7498307",
    //     //     nonce: "484",
    //     //     blockHash: "0x9dbd4514805510bd158515da6ce50a5150907ed01e67d50a6f9c146143a1193d",
    //     //     transactionIndex: "25",
    //     //     from: "0x9294a3639f39c7d95dee36c611d94cebabdf6bb9",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "26124",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "2368673",
    //     //     gasUsed: "26124",
    //     //     confirmations: "3671028",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    //     // {
    //     //     blockNumber: "32023413",
    //     //     timeStamp: "1695562446",
    //     //     hash: "0xa1d456d2ccde31c4e66df85c7ed91102e49981c89c583450a03c5a54546eba96",
    //     //     nonce: "481",
    //     //     blockHash: "0x050506968dd4cbcc3736d6b0f2c1334de9d3dd6145c6fa8606e3315b9b7884bf",
    //     //     transactionIndex: "97",
    //     //     from: "0x9294a3639f39c7d95dee36c611d94cebabdf6bb9",
    //     //     to: "0x203c426c2cbde770d8730fcbe64d16c8064f68fb",
    //     //     value: "0",
    //     //     gas: "26124",
    //     //     gasPrice: "3000000000",
    //     //     isError: "0",
    //     //     txreceipt_status: "1",
    //     //     input: "0x095ea7b3000000000000000000000000dbc1a13490deef9c3c12b44fe77b503c1b0617390000000000000000000000000000000000000000000000000000000000000000",
    //     //     contractAddress: "",
    //     //     cumulativeGasUsed: "8937318",
    //     //     gasUsed: "26124",
    //     //     confirmations: "3671092",
    //     //     methodId: "0x095ea7b3",
    //     //     functionName: "approve(address _spender, uint256 _value)",
    //     // },
    // ]);
    // const [reportsPage, setReportsPage] = useState(1);
    // const [reportsPageSize, setReportsPageSize] = useState(10);
    // const [reportsTotalPages, setReportsTotalPages] = useState(10);

    // const [reportsItemsPerPage, setReportsItemsPerPage] = useState([
    //     { title: 5, isSelected: false, id: uuidv4() },
    //     { title: 10, isSelected: true, id: uuidv4() },
    //     { title: 15, isSelected: false, id: uuidv4() },
    // ]);
    // useEffect(() => {
    //     const reportsStates = JSON.parse(localStorage.getItem("reportsStates"));
    //     if (reportsStates?.pageSize) {
    //         let tempItemsPerPage = [...reportsItemsPerPage];
    //         tempItemsPerPage = tempItemsPerPage.map((item) => ({
    //             title: item.title,
    //             isSelected: item.title === reportsStates.pageSize,
    //             id: item.id,
    //         }));
    //         setReportsItemsPerPage(tempItemsPerPage);
    //         setReportsPageSize(reportsStates.pageSize);
    //     }
    // }, []);

    // const [reportSelectionRange, setReportSelectionRange] = useState(SELECTIONRANGE);
    
    // useEffect(() => {
    //     const selectionRange = JSON.parse(localStorage.getItem("reportsSelectionRange"));
    //     if (selectionRange) {
    //         const { startDate, endDate } = selectionRange;
    //         setReportSelectionRange({
    //             startDate: new Date(startDate),
    //             endDate: new Date(endDate),
    //             key: "selection",
    //         });
    //     }
    // }, []);

    return (
        <StateContext.Provider
            value={{
                // BLOCKCHAINS
                // blockchainsPage,
                // setBlockchainsPage,
                // blockchainsPageSize,
                // setBlockchainsPageSize,
                // blockchainsTotalPages,
                // setBlockchainsTotalPages,
                // blockchainsItemsPerPage,
                // setBlockchainsItemsPerPage,
                // blockchains,
                // setBlockchains,

                // CAMPAIGNS
                // campaignsPage,
                // setCampaignsPage,
                // campaignsPageSize,
                // setCampaignsPageSize,
                // campaignsTotalPages,
                // setCampaignsTotalPages,
                // campaignsItemsPerPage,
                // setCampaignsItemsPerPage,
                // campaignSelectionRange,
                // setCampaignSelectionRange,

                // // CAMPAIGNS WIZARD
                // campaignTabs,
                // setCampaignTabs,
                // displayCreativesModal,
                // setDisplayCreativesModal,
                // creativesState,
                // setCreativesState,

                // REPORTS
                // reportsPage,
                // setReportsPage,
                // reportsPageSize, 
                // setReportsPageSize,
                // reportsTotalPages,
                // setReportsTotalPages,
                // reportsItemsPerPage,
                // setReportsItemsPerPage,
                // reports,
                // setReports,
                // reportSelectionRange, setReportSelectionRange
            }}
        >
            {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);
