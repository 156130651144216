import React, { useState, useEffect } from "react";
import { Modal, Button } from "../../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import localData from "../../../localData";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal, 
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function TrackingHelp() {
    const [showTrackingHelpModal, setShowTrackingHelpModal] = useState("none");
    return (
        <div className="tracking-help">
            <div
                className="help"
                data-tooltip-id="tooltip-tracking"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <div className="instructions">
                        <div className="instructions-header">
                            Step-by-step instructions for intergrating to your site
                        </div>
                        <div className="instructions-body">
                            <ul>
                                <li data-number="1">
                                    Copy the invocation code script by clicking the copy button on the top right
                                </li>
                                <li data-number="2">
                                Paste the copied invocation code script in your website where you want the ads to be shown.
                                </li>
                                <li data-number="3">Submit the changes and publish your website online.</li>
                            </ul>
                        </div>
                    </div>
                )}
            >
                Show step-by-step instructions <div className="help-icon">{info}</div>
                <Tooltip id="tooltip-tracking" className="custom-tooltip tracking-instructions-tooltip" />
            </div>

            <strong>Need more help with integrating the script?</strong>
            <p className="text-style-1">View dedicated integration guides in our docs:</p>
            <Modal
                Child={HelpContent}
                className="tracking-help-modal modal-dialog-centered"
                display={showTrackingHelpModal}
                setDisplay={setShowTrackingHelpModal}
                Toggler={() => (
                    <Button
                        className="tracking-help-btn"
                        name="Integration docs"
                        // startIcon={info}
                        size="sm"
                        color="pale-dark"
                        variant="outlined"
                        onClick={() => setShowTrackingHelpModal("block")}
                    />
                )}
            ></Modal>
        </div>
    );
}

const HelpContent = ({ setIsOpen, setActiveTab }) => {
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Documentation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
            <ul>
        <li data-number='1.'>Copy the invocation code script by clicking the copy button on the top right</li>
        <li data-number='2.'>Paste the copied invocation code script in your website where you want the ads to be shown.</li>
        <li data-number='3.'>Submit the changes and publish your website online.</li>                
    </ul>   
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="close-btn"
                        name="Close"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                </div>
            </div>
        </>
    );
};
