import React, { useEffect, useState } from "react";
import ReportsTable from "../ReportsTable";

export default function Reports() {
 
    return (
        <div className="reports">
            <h2 className="display-2">Withdrawal Reports: </h2>
            <p className="text-style-1">Referrals tracked and commissions earned</p>
            <br />
            <ReportsTable/>
            <br />
            <div className="cover"></div>
        </div>
    );
}

