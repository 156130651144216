import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import EditProfile from "./tabs/EditProfile";
import ResetEmail from "./tabs/ResetEmail";
import ResetPassword from "./tabs/ResetPassword";
import Invite from "./tabs/Invite";

export default function Campaigns() { 
    useEffect(() => {
        document.title = "Profile";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Manage your profile with ease. Update details and customize preferences effortlessly.";
    }, []);
    const { fade } = useGlobalContext().animations;

    const { edit, sendEmail, password, users, pen } = localData.svgs;
    const { avatar } = localData.images;

    const tabs = [
        {
            startIcon: edit,
            title: "Edit profile",
            content: <EditProfile />,
        },
        {
            startIcon: sendEmail,
            title: "Reset email",
            content: <ResetEmail />,
        },
        {
            startIcon: password,
            title: "Reset Password",
            content: <ResetPassword />,
        },
        {
            startIcon: users,
            title: "Invite",
            content: <Invite />,
        },
    ];

    return (
        <motion.main {...fade} className="main profile-page">
            <Navbar title="profile" />

            <div className="row">
                <h2 className="title display-2">My Profile</h2>
                <h4 className="description">
                    Customize your profile and update your information to personalize your experience on our
                    platform
                </h4>
            </div>

            <Tabs tabs={tabs} indicatorIcon={''}/>
        </motion.main>
    );
}
